import { isEmpty, pick } from 'lodash'

import { QUESTION_TYPE } from 'types'

/* eslint-disable no-unused-vars */
export default function filterQuestionnareResponses(responseValues, questions) {
  if (isEmpty(questions)) {
    throw new Error('Must provide questions for filtering')
  }

  // Photos are uploaded individually and are thus removed from the overall
  // questionnaire submission.
  return removePhotoResponses(responseValues, questions)
}

function removePhotoResponses(responseValues, questions) {
  return pick(responseValues, nonPhotoFields(questions))
}

function nonPhotoFields(questions) {
  return questions.reduce((fields, { fieldName, type }) => {
    if (type !== QUESTION_TYPE.PHOTO) {
      fields.push(fieldName)
    }

    return fields
  }, [])
}
