import React from 'react'

const propTypes = {}
const defaultProps = {}

function SafariWindowsDocs() {
  return (
    <div>
      <h1 className="mr-auto margin-top-20">
        Please switch to using Google Chrome with your Windows Computer for this
        app
      </h1>
    </div>
  )
}

SafariWindowsDocs.propTypes = propTypes
SafariWindowsDocs.defaultProps = defaultProps

export default React.memo(SafariWindowsDocs)
