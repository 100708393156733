import React from 'react'
import PropTypes from 'prop-types'
import { GEO_BROWSER_OPTIONS, AMAZON_GEO_DOCS_URL } from 'types'

const propTypes = { browser: PropTypes.string }
const defaultProps = {
  browser: GEO_BROWSER_OPTIONS.CHROME,
}

function IPadDocs({ browser }) {
  const iPadOne = `${AMAZON_GEO_DOCS_URL}/iPadDeviceSettings1-Scroll.jpeg`
  const iPadTwo = `${AMAZON_GEO_DOCS_URL}/iPadDeviceSettings2-FindPrivacyandSecurity-LocationServices.jpeg`
  const iPadThree = `${AMAZON_GEO_DOCS_URL}/iPadDeviceSettings3-PrivacySecurity-LocationServices-BrowserSettings.jpeg`
  const iPadFour = `${AMAZON_GEO_DOCS_URL}/iPadDeviceSettings4-PrivacySecurity-LocationServices-Chrome-Whileusingapp.jpeg`
  const iPadBrowserOne = `${AMAZON_GEO_DOCS_URL}/iPadChrome1-BrowserAskingforLocationPermission.jpeg`
  const iPadBrowserTwo = `${AMAZON_GEO_DOCS_URL}/iPadChrome-Asking-AllowLocation.jpeg`

  return (
    <div>
      <h1 className="mr-auto margin-top-20">For iPads:</h1>
      <p className="mr-auto margin-top-20">
        iPads must have location services enabled from the device settings in
        order for the app to access the location. Follow the below instructions
        to enable location services for the app.{' '}
      </p>
      <h3 className="mr-auto margin-top-20">
        1. You should click on the Settings app from your home screen.
      </h3>
      <h3 className="mr-auto margin-top-20">
        2. Then scroll to find "Privacy and Security"
      </h3>
      <img
        alt="iPad One"
        src={iPadOne}
        className="geo-doc-img geo-doc-img-wider"
      />
      <h3 className="mr-auto margin-top-20">
        3. Then click on "Location Services"
      </h3>
      <img
        alt="iPad Two"
        src={iPadTwo}
        className="geo-doc-img geo-doc-img-wider"
      />
      <h3 className="mr-auto margin-top-20">
        4. Next click on the browser you are using - {browser}
      </h3>
      <img
        alt="iPad Three"
        src={iPadThree}
        className="geo-doc-img geo-doc-img-wider"
      />
      <h3 className="mr-auto margin-top-20">
        5. Now click on the option "While Using the App"
      </h3>
      <img
        alt="iPad Four"
        src={iPadFour}
        className="geo-doc-img geo-doc-img-wider"
      />
      <h3 className="mr-auto margin-top-20">
        6. Next go back into the app and click on Check-In or Check-Out. You may
        see a maps alert asking to allow the browser to use your location. Click
        "Allow Once" or "Allow While Using App" if you get that prompt.
      </h3>
      <img
        alt="iPad Browser One"
        src={iPadBrowserOne}
        className="geo-doc-img geo-doc-img-wider"
      />
      <h3 className="mr-auto margin-top-20">
        7. Finally you may be asked if you want the app to use your location.
        Click "Allow" and you should be able to Check In or Check Out.
      </h3>
      <img
        alt="iPad Browser Two"
        src={iPadBrowserTwo}
        className="geo-doc-img geo-doc-img-wider"
      />
      <h3 className="mr-auto margin-top-20">
        8. Now, in the web app, please click on the "Back to Work Order" button
        at the top of your screen to continue.
      </h3>
    </div>
  )
}

IPadDocs.propTypes = propTypes
IPadDocs.defaultProps = defaultProps

export default React.memo(IPadDocs)
