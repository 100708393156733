/**
 * This hook returns two functions to display submit success and failure messages
 *
 * @name useSubmitMessages
 */

import getFormWideErrorMessage from './getFormWideErrorMessage'
import useToast from './useToast'

function useSubmitMessages() {
  const [showToast] = useToast()

  /**
   * @name displaySubmitSuccess
   * @param {String} message - Message to display
   */
  function displaySubmitSuccess(message) {
    showToast({ message, color: 'success', duration: 3000 })
  }

  /**
   * @name displaySubmitFailure
   * @param {Object} errors - Errors that caused submit to fail
   * @param {Function} dispatch - Redux dispatch function. Not used with toasts.
   * @param {Object} submitError - Errors that caused submit to fail. If the only errors are related internally to Formik validations, this will be null
   */
  function displaySubmitFailure(errors, _, submitError) {
    const message = getFormWideErrorMessage(errors, submitError)
    showToast({ message, color: 'danger', duration: 10000 })
  }

  return { displaySubmitSuccess, displaySubmitFailure }
}

export default useSubmitMessages
