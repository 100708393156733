import React from 'react'
import { AMAZON_GEO_DOCS_URL } from 'types'

const propTypes = {}
const defaultProps = {}

function ChromeIPhoneDocs() {
  const chromeiPhoneOne = `${AMAZON_GEO_DOCS_URL}/iPhone-google-chrome-1.PNG`
  const chromeiPhoneTwo = `${AMAZON_GEO_DOCS_URL}/iPhone-google-chrome-2.PNG`
  const chromeiPhoneThree = `${AMAZON_GEO_DOCS_URL}/iPhone-google-chrome-3.PNG`
  const chromeiPhoneFour = `${AMAZON_GEO_DOCS_URL}/iPhone-google-chrome-4.PNG`
  const chromeiPhoneFive = `${AMAZON_GEO_DOCS_URL}/iPhone-google-chrome-5.PNG`
  const chromeiPhoneSix = `${AMAZON_GEO_DOCS_URL}/iPhone-google-chrome-6.PNG`
  const chromeiPhoneSeven = `${AMAZON_GEO_DOCS_URL}/iPhone-google-chrome-7.PNG`

  return (
    <div>
      <h1 className="mr-auto margin-top-20">For Google Chrome iPhones:</h1>
      <h3 className="mr-auto margin-top-20">
        1. You will need to change the location settings in your iPhone's
        Settings app. So first go to your iPhone's home screen and tap on the
        "Settings" app.
      </h3>
      <h3 className="mr-auto margin-top-20">
        2. Scroll down and tap on the "Google Chrome" app.
      </h3>
      <img
        alt="Chrome iPhone threeDocs-1"
        src={chromeiPhoneOne}
        className="geo-doc-img"
      />
      <h3 className="mr-auto margin-top-20">
        3. Now click on the "Location" option.
      </h3>
      <img
        alt="Chrome iPhone threeDocs-2"
        src={chromeiPhoneTwo}
        className="geo-doc-img"
      />
      <h3 className="mr-auto margin-top-20">
        4. Now click on the "Ask Next Time Or When I ask" option.
      </h3>
      <img
        alt="Chrome iPhone threeDocs-3"
        src={chromeiPhoneThree}
        className="geo-doc-img"
      />
      <h3 className="mr-auto margin-top-20">
        5. Now go back to the Web App and try to Check In or Check Out again.
        You should see a maps alert asking to allow "Chrome" to use your
        location. Click "Allow Once" or "Allow While Using App"
      </h3>
      <img
        alt="Chrome iPhone threeDocs-4"
        src={chromeiPhoneFour}
        className="geo-doc-img"
      />
      <h3 className="mr-auto margin-top-20">
        6. Then you will be asked if you want "TSS" to use your location.
      </h3>
      <img
        alt="Chrome iPhone threeDocs-5"
        src={chromeiPhoneFive}
        className="geo-doc-img"
      />
      <h3 className="mr-auto margin-top-20">
        7. Next you will need to reload your browser for this to take effect.
        Click the three dots at the bottom right of your screen:
      </h3>
      <img
        alt="Chrome iPhone threeDocs-6"
        src={chromeiPhoneSix}
        className="geo-doc-img"
      />
      <h3 className="mr-auto margin-top-20">8. Now click the reload button.</h3>
      <img
        alt="Chrome iPhone threeDocs-7"
        src={chromeiPhoneSeven}
        className="geo-doc-img"
      />
      <h3 className="mr-auto margin-top-20">
        9. You should now be able to Check In and Check Out without issue.
        Please click on the "Back to Work Order" button at the top of your
        screen to return to the Work Order page.
      </h3>
    </div>
  )
}

ChromeIPhoneDocs.propTypes = propTypes
ChromeIPhoneDocs.defaultProps = defaultProps

export default React.memo(ChromeIPhoneDocs)
