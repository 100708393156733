import React from 'react'
import customerAppImage from 'images/workorder-for-customer-app.svg'

const propTypes = {}
const defaultProps = {}

function WorkOrderCustomerAppInstructions() {
  return (
    <div className="flex-col margin-top">
      <div className="mx-auto">
        <img alt="No Results Found" src={customerAppImage} />
      </div>
      <p className="mr-auto margin-top-20">
        Attention: For this Work Order you must use the Service Channel App
      </p>
      <p className="mr-auto">For iPhone:</p>
      <a
        href="https://apps.apple.com/us/app/servicechannel-provider/id1181414929"
        target="_blank"
        rel="noreferrer"
        className="link-button blue-link"
      >
        iPhone App
      </a>
      <p className="mr-auto">For Android:</p>
      <a
        href="https://play.google.com/store/search?q=service%20channel&c=apps&hl=en_US&gl=US"
        target="_blank"
        rel="noreferrer"
        className="link-button blue-link"
      >
        Android App
      </a>
      <p className="mr-auto">Or Call Service Channel at 855-269-6514</p>
    </div>
  )
}

WorkOrderCustomerAppInstructions.propTypes = propTypes
WorkOrderCustomerAppInstructions.defaultProps = defaultProps

export default WorkOrderCustomerAppInstructions
