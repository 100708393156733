import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { IonInput } from '@ionic/react'
import { withFormikAdapter } from 'utils'
import classNames from 'classnames'
import { useMaskito } from '@maskito/react' // https://maskito.dev/getting-started/what-is-maskito
import { omit } from 'lodash'

const propTypes = {
  name: PropTypes.string.isRequired,
  isTouched: PropTypes.bool.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  error: PropTypes.string,
  className: PropTypes.string,
  labelComponent: Types.labelComponent,
  labelProps: PropTypes.object,
  maskOptions: PropTypes.object,
}

const defaultProps = {
  label: '',
  error: '',
  className: '',
  labelComponent: null,
  labelProps: {},
  maskOptions: {},
}

function MaskedInput({
  name,
  label,
  isTouched,
  error,
  labelComponent: LabelComponent,
  labelProps,
  className,
  maskOptions,
  ...rest
}) {
  const inputMask = useMaskito(maskOptions)

  return (
    <IonInput
      ref={async (inputRef) => {
        if (!inputRef) return

        const input = await inputRef.getInputElement()
        inputMask(input)
      }}
      className={classNames(className, {
        'ion-invalid': error,
        'ion-touched': isTouched,
      })}
      name={name}
      label={LabelComponent || label === false ? undefined : label}
      errorText={error}
      {...omit(rest, 'aria-describedby')} // uses built-in ionic error components
    >
      {LabelComponent && (
        <div slot="label">
          <LabelComponent {...labelProps}>{label}</LabelComponent>
        </div>
      )}
    </IonInput>
  )
}

MaskedInput.propTypes = propTypes
MaskedInput.defaultProps = defaultProps

export default withFormikAdapter()(MaskedInput)
