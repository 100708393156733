import { Storage } from '@ionic/storage'

let localStorage

export async function initializeStorage() {
  localStorage = new Storage()
  await localStorage.create()

  return localStorage
}

export function storageSet(key, val) {
  localStorage.set(key, val)
}

export async function storageGet(key) {
  const val = await localStorage.get(key)
  return val
}

export async function storageRemove(key) {
  await localStorage.remove(key)
}
