import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import * as Views from './views'
import { RouterOutlet } from 'components'
import Layout from './Layout'

const propTypes = {}
const defaultProps = {}

function Routes() {
  const { path } = useRouteMatch()
  const routes = [
    {
      url: `${path}/show/:workOrderId`,
      component: Views.WorkOrderShow,
    },
    {
      url: `${path}/search`,
      component: Views.WorkOrderSearch,
      layoutParams: {
        hideBackButton: true,
        showBackdrop: true,
      },
    },
    {
      url: `${path}/checked_in/:workOrderId`,
      component: Views.WorkOrderCheckedIn,
      layoutParams: {
        hideBackButton: true,
      },
    },
    {
      url: `${path}/no_results`,
      component: Views.WorkOrderSearchNoResults,
    },
    {
      url: `${path}/customer_app_work_order`,
      component: Views.WorkOrderCustomerAppInstructions,
    },
  ]

  return <RouterOutlet routes={routes} Layout={Layout} />
}

Routes.propTypes = propTypes
Routes.defaultProps = defaultProps

export default Routes
