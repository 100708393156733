import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import LogInForm from '../forms/LogInForm'
import * as apiActions from 'api-actions'
import { useSubmitMessages } from 'utils'
import { useHistory } from 'react-router-dom'

const propTypes = {
  authenticateUser: PropTypes.func.isRequired,
}
const defaultProps = {}

function Login({ authenticateUser }) {
  const history = useHistory()
  const { displaySubmitSuccess, displaySubmitFailure } = useSubmitMessages()

  return (
    <>
      <div className="login-content">
        <h1>Log In</h1>

        <LogInForm
          initialValues={{
            email: '',
            password: '',
          }}
          onSubmit={async (params) => {
            try {
              await authenticateUser(params)
              await displaySubmitSuccess('Succesfully logged in.')
              history.replace('/')
            } catch (error) {
              // The error returned here is an API HTTPError object, that should have an errors key on it
              const errors = error.errors
              displaySubmitFailure(errors, null, error)
            }
          }}
        />
      </div>
    </>
  )
}

Login.propTypes = propTypes
Login.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {
  authenticateUser: apiActions.authenticateUser,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Login)
