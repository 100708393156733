import React from 'react'
import PropTypes from 'prop-types'
import { IonItem, IonLabel } from '@ionic/react'
import { startCase } from 'lodash'

import InputError from './InputError'
import * as Types from 'types'

const propTypes = {
  name: PropTypes.string.isRequired,
  meta: Types.meta,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  hideErrorLabel: PropTypes.bool,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  requiredIndicator: PropTypes.string,
  disabled: PropTypes.bool,
}
const defaultProps = {
  name: '',
  hideErrorLabel: false,
  disabled: false,
  requiredIndicator: '',
}

function LabeledField({
  name,
  meta,
  className,
  children,
  hideErrorLabel,
  label,
  required,
  requiredIndicator,
  disabled,
}) {
  const displayedLabel = label ?? startCase(name)
  const invalid = Boolean(meta.error)
  return (
    <IonItem
      {...{
        className,
        disabled: disabled,
        lines: 'none',
      }}
    >
      {label !== false && (
        <IonLabel position="stacked" className="stacked-label">
          {displayedLabel}
          {required && requiredIndicator && (
            <span className="required-indicator" aria-hidden="true">
              {requiredIndicator}
            </span>
          )}
        </IonLabel>
      )}
      {children}
      {!hideErrorLabel && (
        <InputError
          name={name}
          invalid={invalid}
          error={meta.error}
          touched={meta.touched}
        />
      )}
    </IonItem>
  )
}

LabeledField.propTypes = propTypes
LabeledField.defaultProps = defaultProps

export default React.memo(LabeledField)
