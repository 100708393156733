import React from 'react'
import { useField } from 'formik'
import {
  generateInputErrorId,
  wrapDisplayName,
  convertNameToLabel,
} from 'utils'
import { isDevelopment } from 'config'

// Returns true if the argument is an event
// Copied from https://github.com/erikras/redux-form/blob/master/src/events/isEvent.js
function isEvent(value) {
  return Boolean(value && value.stopPropagation && value.preventDefault)
}

function composeEventHandlers({
  fieldName,
  customEventHandler,
  defaultEventHandler,
}) {
  return function onEvent(value) {
    // Create a fake event if necessary since formik expects an event object
    const changeEvent = isEvent(value)
      ? value
      : { target: { name: fieldName, value } }

    // Set the value of the checkbox to its checked state to allow it to be controlled
    if (changeEvent.detail?.checked != undefined) {
      changeEvent.target.value = changeEvent.detail.checked
    }

    defaultEventHandler(changeEvent)

    if (customEventHandler) customEventHandler(changeEvent)
  }
}

function withFormikAdapter() {
  return (IonInputComponent) => {
    function WrappedInputComponent({ onChange, onBlur, name, label, ...rest }) {
      const [field, meta] = useField({ name, ...rest })
      const labelToDisplay = label ?? convertNameToLabel(name)

      if (
        !labelToDisplay &&
        !rest['aria-label'] &&
        !rest['aria-labelledby'] &&
        isDevelopment()
      ) {
        // eslint-disable-next-line no-console
        console.warn(
          'Make sure you account for assistive technologies when hiding an input label without providing an aria-label'
        )
      }

      const onChangeEventHandler = composeEventHandlers({
        fieldName: field.name,
        customEventHandler: onChange,
        defaultEventHandler: field.onChange,
      })

      const isDisplayingInputError = meta.error && meta.touched

      return (
        <IonInputComponent
          name={field.name}
          value={field.value}
          label={labelToDisplay}
          onIonInput={onChangeEventHandler}
          onIonChange={onChangeEventHandler}
          onIonBlur={composeEventHandlers({
            fieldName: field.name,
            customEventHandler: onBlur,
            defaultEventHandler: field.onBlur,
          })}
          isTouched={meta.touched}
          error={meta.error}
          aria-describedby={
            isDisplayingInputError ? generateInputErrorId(name) : undefined
          }
          {...rest}
        />
      )
    }

    WrappedInputComponent.displayName = wrapDisplayName(
      IonInputComponent,
      'withFormikAdapter'
    )
    return WrappedInputComponent
  }
}

export default withFormikAdapter
