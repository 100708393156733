import React from 'react'
import PropTypes from 'prop-types'
import { IonContent, IonPage } from '@ionic/react'
import { Header, BackButton } from 'components'

const propTypes = {
  children: PropTypes.node.isRequired,
  hideBackButton: PropTypes.bool,
  backButtonText: PropTypes.string,
}
const defaultProps = {}

function Layout({ children, hideBackButton, backButtonText }) {
  return (
    <IonPage>
      <Header />
      {!hideBackButton && <BackButton backButtonText={backButtonText} />}
      <IonContent>
        <div className="content-container">
          <div className="content-container-internal">{children}</div>
        </div>
      </IonContent>
    </IonPage>
  )
}

Layout.propTypes = propTypes
Layout.defaultProps = defaultProps

export default Layout
