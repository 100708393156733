import { isEmpty } from 'lodash'

import isPresent from './isPresent'

export default function isValidationError(errors, submitError) {
  const hasValidationErrors = isPresent(errors) && isEmpty(submitError)
  function decideifSubmitErrorIsCustom(submitError) {
    // One example of a cubstom submitError is the error we get
    // when someone does not accept geolocation tracking for check-in or check-out
    return submitError?.name !== 'HttpError'
  }
  const hasCustomValidationErrors = decideifSubmitErrorIsCustom(submitError)
  return hasValidationErrors || hasCustomValidationErrors
}
