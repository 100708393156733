import PropTypes from 'prop-types'

export const labelComponent = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.elementType,
])

export const fieldOptions = PropTypes.arrayOf(
  PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]).isRequired,
    }),
  ])
)

//  MODEL TYPES
//  ---------

export const location = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  name: PropTypes.string,
  addressLineOne: PropTypes.string,
  addressLineTwo: PropTypes.string,
})

export const product = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  name: PropTypes.string,
})

export const machineAsset = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  make: PropTypes.string,
  serialNumber: PropTypes.string,
  name: PropTypes.string,
})

export const image = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  url: PropTypes.string,
  filename: PropTypes.string,
})

export const trip = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  date: PropTypes.string,
  notes: PropTypes.string,
  abuseNeglectClaims: PropTypes.string,
  rootCause: PropTypes.string,
  status: PropTypes.string,
  currentTripImages: PropTypes.arrayOf(image),
  operable: PropTypes.string, // unsure if this is right? sounds like it should be a bool
})

export const workOrder = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  name: PropTypes.string,
  companyName: PropTypes.string,
  problemDescription: PropTypes.string,
  orderStatus: PropTypes.string,
  orderSubStatus: PropTypes.string,
  nte: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  scheduledServiceDueDate: PropTypes.string,
  shouldRunGeolocationCheck: PropTypes.bool,
  trips: PropTypes.arrayOf(trip),
  machineAsset: machineAsset,
  location: location,
})

export const user = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  email: PropTypes.string,
  name: PropTypes.string,
})

export const Question = PropTypes.shape({
  // Constraints are defined by ValidateJS. See: https://validatejs.org/
  constraints: PropTypes.object,
  constraintText: PropTypes.string,
  photoType: PropTypes.string,
  questionId: PropTypes.string,
  required: PropTypes.bool,
  title: PropTypes.string,
  type: PropTypes.string,
})

export const Questionnaire = PropTypes.arrayOf(Question)

export const selectOption = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
})

//  FORM TYPES
//  ---------

export const input = PropTypes.shape({
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
    PropTypes.bool,
  ]),
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
})

export const meta = PropTypes.shape({
  dirty: PropTypes.bool,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  touched: PropTypes.bool,
  valid: PropTypes.bool,
})

export const field = PropTypes.shape({
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.any,
})

//  UTIL TYPES
//  ---------

export const route = PropTypes.shape({
  url: PropTypes.string.isRequired,
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  layoutParams: PropTypes.object,
  provider: PropTypes.object,
})

//  CONSTANTS
//  ---------

export const TOAST_DEFAULT_DURATION = 3000 // ms
export const WORK_STATUS_CHARACTER_LIMIT = 750 // characters
export const ABUSE_NEGLECT_NOTES_CHARACTER_LIMIT = 255
export const OPERATIONAL_OPTIONS = ['yes', 'no']

export const ERROR_CODE = {
  TRIP_TIMED_OUT: 'trip_timed_out',
  NOT_FOUND: 'not_found',
  NOT_FOR_TSS: 'not_for_tss_app',
  UNKNOWN_ERROR: 'unknown_error',
}

export const ERROR_MESSAGES = {
  GEO_DENIAL_ERROR:
    'This app requires geolocation tracking. There was a geolocating error submitting',
}

export const ORDER_STATUS = {
  TECH_ON_SITE: 'Tech On Site',
}

export const ORDER_SUB_STATUS = {
  IVR_TIMEOUT: 'IVR Timeout',
}

export const PHOTO_TYPE = {
  CUSTOM: 'CustomTripPhoto',
  MACHINE_STATE: 'PreWorkMachineStateTripPhoto',
  SERIAL_NUMBER: 'SerialNumberTripPhoto',
  UNIT_CONDITION: 'UnitConditionTripPhoto',
  WORK_PERFORMED: 'WorkPerformedTripPhoto',
  ABUSE: 'AbuseTripPhoto',
  DEFECT: 'DefectTripPhoto',
}

export const QUESTION_TYPE = {
  CHOICE: 'choice',
  INPUT: 'input',
  PHOTO: 'photo',
}

export const ROOT_CAUSE_OPTIONS = [
  'Manufacturer Defect',
  'Impact or a collision',
  'Neglect or lack of maintenance',
  'No Problem Found',
  'Normal wear',
  'Operator Error',
  'Tampered with, and or is missing components',
]

export const ROOT_CAUSE_NEGLECT_OPTIONS = [
  'Impact or a collision',
  'Neglect or lack of maintenance',
  'Tampered with, and or is missing components',
]

export const ROOT_CAUSE_DEFECT = 'Manufacturer Defect'

export const TRIP_STATUS = {
  CLOSED_NO_CHECKOUT: 'Closed No Checkout',
  EXPIRED: 'Expired',
}

export const WORK_STATUS_OPTIONS = [
  'Awaiting Parts',
  'Estimate',
  'Work Completed',
]

export const GEO_BROWSER_OPTIONS = { CHROME: 'Google Chrome', SAFARI: 'Safari' }

export const GEO_DEVICE_OPTIONS = {
  IPHONE: 'iPhone',
  IPAD: 'iPad',
  ANDROID: 'Android',
  WINDOWS: 'Windows Computer',
  MAC: 'Mac Computer',
}

export const AMAZON_GEO_DOCS_URL =
  'https://geolocation-docs.s3.us-east-2.amazonaws.com'
