import { useIonToast } from '@ionic/react'

// wrapper for ionic's useIonToast that positions it at the top of the screen
function useToast() {
  const [present, dismiss] = useIonToast()

  const presentWrapper = (props) => {
    present({
      position: 'top',
      ...props,
    })
  }

  return [presentWrapper, dismiss]
}

export default useToast
