import React from 'react'
import { AMAZON_GEO_DOCS_URL } from 'types'

const propTypes = {}
const defaultProps = {}

function SafariMacDocs() {
  const safariMacOne = `${AMAZON_GEO_DOCS_URL}/mac-safari-1.png`
  const safariMacTwo = `${AMAZON_GEO_DOCS_URL}/mac-safari-2.png`
  const safariMacThree = `${AMAZON_GEO_DOCS_URL}/mac-safari-3.png`
  const safariMacFour = `${AMAZON_GEO_DOCS_URL}/mac-safari-4.png`
  const safariMacFive = `${AMAZON_GEO_DOCS_URL}/mac-safari-5.png`

  return (
    <div>
      <h1 className="mr-auto margin-top-20">For Safari Mac Computers:</h1>
      <h3 className="mr-auto margin-top-20">
        1. First, click on the "Safari" menu in the top left corner of your
        screen.
      </h3>
      <img
        alt="Safari Mac-1"
        src={safariMacOne}
        className="geo-doc-img geo-doc-img-wider"
      />
      <h3 className="mr-auto margin-top-20">
        2. Next, click on the "Settings" option.
      </h3>
      <img
        alt="Safari Mac-2"
        src={safariMacTwo}
        className="geo-doc-img geo-doc-img-wider"
      />
      <h3 className="mr-auto margin-top-20">
        3. Now scroll down to the Location option on the left, and click on the
        dropdown next to the app URL you are using.
      </h3>
      <img
        alt="Safari Mac-3"
        src={safariMacThree}
        className="geo-doc-img geo-doc-img-wider"
      />
      <h3 className="mr-auto margin-top-20">
        4. Now select "Allow" from the options.
      </h3>
      <img
        alt="Safari Mac-4"
        src={safariMacFour}
        className="geo-doc-img geo-doc-img-wider"
      />
      <h3 className="mr-auto margin-top-20">
        5. Finally, click back on the web page and refresh the page for this
        change to take effect:
      </h3>
      <img
        alt="Safari Mac-5"
        src={safariMacFive}
        className="geo-doc-img geo-doc-img-wider"
      />
      <h3 className="mr-auto margin-top-20">
        6. You should now be able to Check In or Check Out without being asked
        to give your geolocation. Please click on the "Back to Work Order"
        button at the top of your screen to get back to the Work Order page
        where you can Check In or Check Out.
      </h3>
    </div>
  )
}

SafariMacDocs.propTypes = propTypes
SafariMacDocs.defaultProps = defaultProps

export default React.memo(SafariMacDocs)
