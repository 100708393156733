export default function findImagesOfType(images, type) {
  return images
    .filter(({ photoType }) => photoType === type)
    .map(({ filename, id, url }) => ({
      id,
      name: filename,
      type: 'image/*',
      url,
    }))
}
