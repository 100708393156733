import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import * as Types from 'types'
import {
  IonAccordionGroup,
  IonItem,
  IonLabel,
  IonAccordion,
} from '@ionic/react'
import TripPreview from './TripPreview'
import { Link } from 'react-router-dom'
import infoImg from 'images/info.svg'

const propTypes = {
  workOrder: Types.workOrder.isRequired,
  selectImage: PropTypes.func.isRequired,
}

const defaultProps = {}

function WorkOrderPreview({ workOrder, selectImage }) {
  const nte = workOrder.nte?.toFixed(2) || ''

  // hide the most recent trip if it is in progress
  const trips = workOrder.trips
  if (
    trips?.length > 0 &&
    workOrder.orderStatus == 'Tech On Site' &&
    trips[trips.length - 1].status == 'Tech On Site'
  )
    trips.splice(trips.length - 1)

  return (
    <div className="work-order-preview-content">
      {workOrder.shouldRunGeolocationCheck && (
        <Link to={`/geolocation_docs`} className="geo-doc-link">
          Problems with Geolocation
          <img alt="Info" src={infoImg} className="geo-doc-info-img" />
        </Link>
      )}
      <h3 className="m-t-8">Site</h3>
      <p className="underline">{workOrder.location?.name}</p>
      <p className="underline">{workOrder.location?.addressLineOne}</p>
      <p className="underline">{workOrder.location?.addressLineTwo}</p>
      <h3>Problem Description</h3>
      <p>{workOrder.problemDescription}</p>
      <h3>Asset Name</h3>
      <p>{workOrder.machineAsset?.name || ''}</p>
      <h3>NTE</h3>
      <p>{`$${nte}`}</p>
      <h3>Scheduled Service Date</h3>
      <p>{workOrder.scheduledServiceDueDate}</p>
      <h3>Previous Visits</h3>
      <IonAccordionGroup>
        {trips.length > 0 ? (
          trips.map((trip) => {
            return (
              <IonAccordion
                key={trip.id}
                className="card-styling card-styling-wo"
              >
                <IonItem className="body-medium" slot="header" lines="none">
                  <IonLabel>{`Visit on ${trip.date}`}</IonLabel>
                </IonItem>
                <div slot="content">
                  <TripPreview
                    workOrder={workOrder}
                    trip={trip}
                    selectImage={selectImage}
                  />
                </div>
              </IonAccordion>
            )
          })
        ) : (
          <p>No previous trips</p>
        )}
      </IonAccordionGroup>
    </div>
  )
}

WorkOrderPreview.propTypes = exact(propTypes)
WorkOrderPreview.defaultProps = defaultProps

export default React.memo(WorkOrderPreview)
