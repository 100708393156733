import React from 'react'
import PropTypes from 'prop-types'
import { IonButton, IonSpinner } from '@ionic/react'
import classnames from 'classnames'
import { noop } from 'lodash'

const propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  showSpinner: PropTypes.bool,
  buttonClassName: PropTypes.string,
  children: PropTypes.node,
  expand: PropTypes.string,
}

const defaultProps = {
  isSubmitting: false,
  showSpinner: false,
  children: 'Submit',
  expand: 'block',
}

function SubmitButton({
  isSubmitting,
  showSpinner,
  buttonClassName,
  children,
  expand,
  ...rest
}) {
  const classNames = [classnames('button-primary')]
  if (buttonClassName) {
    classNames.push(buttonClassName)
  }
  const displaySpinner = isSubmitting && showSpinner
  return (
    <IonButton
      disabled={isSubmitting}
      shape="round"
      type="submit"
      className={classNames.join(' ')}
      expand={expand}
      onClick={isSubmitting ? noop : undefined}
      data-test="submit"
      size="large"
      {...rest}
    >
      {displaySpinner ? <IonSpinner /> : children}
    </IonButton>
  )
}

SubmitButton.propTypes = propTypes
SubmitButton.defaultProps = defaultProps

export default React.memo(SubmitButton)
