import React from 'react'
import exact from 'prop-types-exact'
import PropTypes from 'prop-types'
import { IonButton, IonIcon, IonText } from '@ionic/react'
import { chevronBackOutline } from 'ionicons/icons'
import { useHistory } from 'react-router-dom'

const propTypes = {
  backButtonText: PropTypes.string,
}

const defaultProps = {
  backButtonText: 'Back to Search',
}

function BackButton({ backButtonText }) {
  const history = useHistory()
  return (
    <div className="back-button-container">
      <IonButton
        color="clear"
        onClick={() => {
          if (history.length > 1) {
            history.goBack()
          } else {
            // The default page to go back to if there is no history:
            history.push('/work_orders/search')
          }
        }}
      >
        <IonIcon slot="start" color="dark" icon={chevronBackOutline} />
        <IonText color="dark">{backButtonText}</IonText>
      </IonButton>
    </div>
  )
}

BackButton.propTypes = exact(propTypes)
BackButton.defaultProps = defaultProps

export default React.memo(BackButton)
