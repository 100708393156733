import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import {
  IonDatetime,
  IonLabel,
  IonModal,
  IonDatetimeButton,
} from '@ionic/react'
import { withFormikAdapter } from 'utils'
import InputError from './InputError'
import { formatISO } from 'date-fns'
import { useFormikContext } from 'formik'

const propTypes = {
  name: PropTypes.string.isRequired,
  isTouched: PropTypes.bool.isRequired,
  value: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  error: PropTypes.string,
  labelComponent: Types.labelComponent,
  labelProps: PropTypes.object,
  displayComponent: PropTypes.elementType,
}

const defaultProps = {
  value: null,
  label: '',
  error: '',
  labelComponent: IonLabel,
  labelProps: {},
  displayComponent: IonModal,
}

function DateButton({
  name,
  isTouched,
  value,
  label,
  error,
  labelComponent: LabelComponent,
  labelProps,
  displayComponent: DisplayComponent,
  ...rest
}) {
  const datetimeId = `datetime-${name}`
  const {
    'aria-label': ariaLabel,
    'aria-labelledby': ariaLabelledby,
    ...inputProps
  } = rest
  const labelId = label && !ariaLabel ? `label-${name}` : undefined
  const { setFieldValue } = useFormikContext()

  useEffect(() => {
    /* If an initial date value isn't provided for an input component using IonDatetimeButton,
		which is frequently preferred so that Ionic defaults to the current date,
		make sure Formik has the current date stored as a value for the input
		since the input button will appear already filled with the current date. */
    if (!value) {
      setFieldValue(name, formatISO(new Date()), false)
    }
  }, [value, name, setFieldValue])

  return (
    <>
      {label && (
        <LabelComponent id={labelId} {...labelProps}>
          {label}
        </LabelComponent>
      )}
      <IonDatetimeButton
        aria-label={ariaLabel}
        aria-labelledby={ariaLabelledby ?? labelId}
        datetime={datetimeId}
      />
      <InputError {...{ isTouched, error, name }} />
      <DisplayComponent keepContentsMounted={true}>
        <IonDatetime
          name={name}
          id={datetimeId}
          value={value === '' ? null : value} // Empty string is not a valid option for ion-datetime value
          {...inputProps}
        />
      </DisplayComponent>
    </>
  )
}

DateButton.propTypes = propTypes
DateButton.defaultProps = defaultProps

export default withFormikAdapter()(DateButton)
