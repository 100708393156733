import React from 'react'
import { Form, Formik } from 'formik'
import { SubmitButton, FormWideErrorReporter } from 'formik-components'
import PropTypes from 'prop-types'
import alertImg from 'images/alert.svg'

const propTypes = {
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  requireGeolocationCheck: PropTypes.bool,
}

const defaultProps = {}

function WorkOrderCheckInForm({
  requireGeolocationCheck,
  initialValues,
  onSubmit,
}) {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnBlur={false}
    >
      {({ isSubmitting }) => {
        return (
          <Form className="w-100p">
            <FormWideErrorReporter />
            {requireGeolocationCheck && (
              <div className="geo-warning-container">
                <img alt="Alert" src={alertImg} className="geo-doc-alert-img" />
                Geolocation <strong> MUST </strong>
                be accepted to check in
                <div className="geo-warning-text"></div>
              </div>
            )}
            <SubmitButton
              expand="full"
              isSubmitting={isSubmitting}
              showSpinner={true}
            >
              Check In
            </SubmitButton>
          </Form>
        )
      }}
    </Formik>
  )
}

WorkOrderCheckInForm.propTypes = propTypes
WorkOrderCheckInForm.defaultProps = defaultProps

export default React.memo(WorkOrderCheckInForm)
