import { isEmpty } from 'lodash'

export default function formatQuestionnaireResponses(
  responseValues,
  questions,
  trip
) {
  if (isEmpty(questions) || isEmpty(trip)) {
    throw new Error('Must provide questions and trip for formatting')
  }

  return Object.entries(responseValues).map(([key, value]) => {
    const responseQuestion = questions.find(
      ({ fieldName }) => fieldName === key
    )

    if (isEmpty(responseQuestion)) {
      throw new Error(
        `questionnaire response key [${key}] has no corresponding question in the questionnaire`
      )
    }

    return {
      questionId: responseQuestion.questionId,
      responseText: value,
    }
  })
}
