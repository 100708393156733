import React, { useState } from 'react'
import { ERROR_MESSAGES, GEO_BROWSER_OPTIONS, GEO_DEVICE_OPTIONS } from 'types'
import { getBrowserAndDevice } from 'utils'
import WorkOrderGeoDocsForm from '../forms/WorkOrderGeoDocsForm'
import ChromeIPhoneDocs from '../components/geoDocs/ChromeIPhoneDocs'
import SafariIPhoneDocs from '../components/geoDocs/SafariIPhoneDocs'
import SafariAndroidDocs from '../components/geoDocs/SafariAndroidDocs'
import SafariWindowsDocs from '../components/geoDocs/SafariWindowsDocs'
import SafariMacDocs from '../components/geoDocs/SafariMacDocs'
import ChromeAndroidDocs from '../components/geoDocs/ChromeAndroidDocs'
import ChromeComputerDocs from '../components/geoDocs/ChromeComputerDocs'
import IPadDocs from '../components/geoDocs/IPadDocs'

const propTypes = {}
const defaultProps = {}

function WorkOrderGeoDocs() {
  const browserDeviceObject = getBrowserAndDevice()
  const browser =
    browserDeviceObject.browser !== 'Unknown' ? browserDeviceObject.browser : ''
  const device =
    browserDeviceObject.device !== 'Unknown' ? browserDeviceObject.device : ''

  const [selectedBrowser, setSelectedBrowser] = useState(browser)
  const [selectedDevice, setSelectedDevice] = useState(device)

  const renderDocsComponent = () => {
    const browserDeviceCombo = `${selectedBrowser}-${selectedDevice}`
    switch (browserDeviceCombo) {
      case `${GEO_BROWSER_OPTIONS.CHROME}-${GEO_DEVICE_OPTIONS.IPHONE}`:
        return <ChromeIPhoneDocs />
      case `${GEO_BROWSER_OPTIONS.CHROME}-${GEO_DEVICE_OPTIONS.IPAD}`:
        return <IPadDocs browser={GEO_BROWSER_OPTIONS.CHROME} />
      case `${GEO_BROWSER_OPTIONS.CHROME}-${GEO_DEVICE_OPTIONS.ANDROID}`:
        return <ChromeAndroidDocs />
      case `${GEO_BROWSER_OPTIONS.CHROME}-${GEO_DEVICE_OPTIONS.WINDOWS}`:
        return <ChromeComputerDocs />
      case `${GEO_BROWSER_OPTIONS.CHROME}-${GEO_DEVICE_OPTIONS.MAC}`:
        return <ChromeComputerDocs />
      case `${GEO_BROWSER_OPTIONS.SAFARI}-${GEO_DEVICE_OPTIONS.IPHONE}`:
        return <SafariIPhoneDocs />
      case `${GEO_BROWSER_OPTIONS.SAFARI}-${GEO_DEVICE_OPTIONS.IPAD}`:
        return <IPadDocs browser={GEO_BROWSER_OPTIONS.SAFARI} />
      case `${GEO_BROWSER_OPTIONS.SAFARI}-${GEO_DEVICE_OPTIONS.ANDROID}`:
        return <SafariAndroidDocs />
      case `${GEO_BROWSER_OPTIONS.SAFARI}-${GEO_DEVICE_OPTIONS.WINDOWS}`:
        return <SafariWindowsDocs />
      case `${GEO_BROWSER_OPTIONS.SAFARI}-${GEO_DEVICE_OPTIONS.MAC}`:
        return <SafariMacDocs />
      default:
        return null
    }
  }

  return (
    <div className="flex-col margin-top">
      <h3 className="mr-auto margin-top-20">Information on Location Sharing</h3>
      <p className="mr-auto margin-top-20">
        When Checking In or Checking Out, you will be prompted to share your
        location. If you deny sharing your location, you will not be able to
        Check In or Check Out, and you will see a message "
        {`${ERROR_MESSAGES.GEO_DENIAL_ERROR}`}" every time you try to Check In
        or Check Out.
      </p>
      <p className="mr-auto margin-top-20 margin-bottom-30">
        In order to resolve this issue, you will need to go into your browser's
        settings and remove your location block for the app. We have
        instructions for how to do this for Safari and Chrome below.
      </p>
      <WorkOrderGeoDocsForm
        initialValues={{ browser: browser, device: device }}
        onSubmit={(values) => {
          setSelectedBrowser(values.browser)
          setSelectedDevice(values.device)
        }}
      />
      {renderDocsComponent()}
    </div>
  )
}

WorkOrderGeoDocs.propTypes = propTypes
WorkOrderGeoDocs.defaultProps = defaultProps

export default WorkOrderGeoDocs
