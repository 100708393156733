import React from 'react'
import PropTypes from 'prop-types'
import { IonContent, IonPage } from '@ionic/react'
import { Header, BackButton } from 'components'
import searchBackdropImg from 'images/search-backdrop.png'

const propTypes = {
  children: PropTypes.node.isRequired,
  hideBackButton: PropTypes.bool,
  showBackdrop: PropTypes.bool,
  backButtonText: PropTypes.string,
}
const defaultProps = {}

function Layout({ children, hideBackButton, backButtonText, showBackdrop }) {
  const searchBackdrop = (
    <div className="search-img-container">
      <img alt="TSS" src={searchBackdropImg} className="background-img" />
    </div>
  )

  return (
    <IonPage>
      <Header />
      {!hideBackButton && <BackButton backButtonText={backButtonText} />}
      <IonContent>
        {showBackdrop && searchBackdrop}
        <div className="content-container">
          <div className="content-container-internal">{children}</div>
        </div>
      </IonContent>
    </IonPage>
  )
}

Layout.propTypes = propTypes
Layout.defaultProps = defaultProps

export default Layout
