import React, { useEffect, useState } from 'react'
import Routes from './Routes'
import store from 'store'
import { Provider } from 'react-redux'
import { IonReactRouter } from '@ionic/react-router'
import { IonApp, IonSpinner, setupIonicReact } from '@ionic/react'
import { initializeStorage } from 'local-storage'
// import { SplashScreen } from '@capacitor/splash-screen'

// Log runtime errors with sentry service
import 'sentry'

// Core CSS required for Ionic components to work properly
import '@ionic/react/css/core.css'

// Basic CSS for apps built with Ionic
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

// Application-specific styles
import '../../scss/application.scss'

function App() {
  // Hide the splash screen (recommended by: https://capacitorjs.com/docs/apis/splash-screen#hiding-the-splash-screen)
  // useEffect(() => {
  //   async function hideSplashScreen() {
  //     await SplashScreen.hide()
  //   }

  //   hideSplashScreen()
  // }, [])
  const [localStorage, setLocalStorage] = useState()

  // Mandatory Ionic initialization. See https://ionicframework.com/docs/react/config
  // for more information on setting a global Ionic app configuration.
  setupIonicReact()

  useEffect(() => {
    setLocalStorage(initializeStorage())
  }, [])

  if (!localStorage) return <IonSpinner />

  return (
    <Provider store={store}>
      <IonApp>
        <IonReactRouter>
          <Routes />
        </IonReactRouter>
      </IonApp>
    </Provider>
  )
}

export default App
