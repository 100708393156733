import React from 'react'
import { Link } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { SubmitButton, Input, FormWideErrorReporter } from 'formik-components'
import PropTypes from 'prop-types'
import * as Yup from 'yup'

const propTypes = {
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

const defaultProps = {}

const VALIDATION_SCHEMA = Yup.object({
  email: Yup.string()
    .email('Email must be valid')
    .required("Email can't be blank"),
  password: Yup.string().required("Password can't be blank"),
})

function LogInForm({ initialValues, onSubmit }) {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={VALIDATION_SCHEMA}
      onSubmit={onSubmit}
      validateOnBlur={false}
    >
      {({ isSubmitting }) => {
        return (
          <Form>
            <FormWideErrorReporter />
            <Input
              name="email"
              label="Email"
              type="email"
              required={true}
              requiredIndicator="*"
              placeholder="Enter email address"
              dataTest="email"
            />
            <Input
              name="password"
              label="Password"
              type="password"
              required={true}
              requiredIndicator="*"
              placeholder="Enter password"
              dataTest="password"
            />
            <div className="forgot-pass-link">
              <Link to="/auth/forgotpassword">
                <b>Forgot your password?</b>
              </Link>
            </div>
            <SubmitButton isSubmitting={isSubmitting}>Log In</SubmitButton>
          </Form>
        )
      }}
    </Formik>
  )
}

LogInForm.propTypes = propTypes
LogInForm.defaultProps = defaultProps

export default React.memo(LogInForm)
