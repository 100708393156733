import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import * as apiActions from 'api-actions'
import { useSubmitMessages, transformDeviseErrors } from 'utils'
import { useHistory, useParams } from 'react-router-dom'
import ResetPasswordForm from '../forms/ResetPasswordForm'

const propTypes = {
  createAccount: PropTypes.func.isRequired,
  logOutUser: PropTypes.func.isRequired,
}
const defaultProps = {}

function CreateAccount({ createAccount, logOutUser }) {
  const history = useHistory()
  const { displaySubmitSuccess, displaySubmitFailure } = useSubmitMessages()
  const { token } = useParams()

  // log out the current user (avoids weird conflicts if a user accidentally hits the page while logged in)
  useEffect(() => {
    logOutUser()
  })

  function transformErrorsForCreateAccount(error) {
    // The invitation token error can be confusing, so we handle that separately.
    if (!Array.isArray(error.errors) && error.errors['invitationToken']) {
      return [
        {
          title:
            'Invitation token is invalid. Please contact TSS and ask them to send a new invitation email, and once sent, please use the link in the new email to set your password.',
        },
      ]
    } else {
      return transformDeviseErrors(error.errors)
    }
  }

  return (
    <>
      <div className="login-content">
        <h1>You Can Now Finish Creating Your Account</h1>
        <p>Create a password to log into your account.</p>
        <ResetPasswordForm
          isNewPassword={true}
          initialValues={{ password: '', passwordConfirmation: '' }}
          onSubmit={async (params) => {
            try {
              await createAccount({ ...params, invitation_token: token })
              await displaySubmitSuccess('Your account has been created!')
              history.replace('/auth/login')
            } catch (error) {
              const errors = transformErrorsForCreateAccount(error)
              return displaySubmitFailure(errors, null, error)
            }
          }}
        />
      </div>
    </>
  )
}

CreateAccount.propTypes = propTypes
CreateAccount.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {
  createAccount: apiActions.createAccount,
  logOutUser: apiActions.logOutUser,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  CreateAccount
)
