import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { IonHeader, IonToolbar, IonButton, IonButtons } from '@ionic/react'
import { selectors as authSelectors } from 'auth-reducer'
import logoImg from 'images/logo.png'
import logoutImg from 'images/logout.svg'
import { useHistory } from 'react-router-dom'
import { useToast } from 'utils'
import * as apiActions from 'api-actions'

const propTypes = {
  currentUser: Types.user,
  logOutUser: PropTypes.func.isRequired,
}
const defaultProps = {}

function Header({ currentUser, logOutUser }) {
  const history = useHistory()
  const [showToast] = useToast()

  const logOut = () => {
    logOutUser()
    history.replace('/auth/login')
    showToast({
      message: 'You have been logged out.',
      color: 'success',
      duration: 3000,
    })
  }

  return (
    <IonHeader className="header-container">
      <IonToolbar className="header-toolbar">
        <IonButtons slot="start">
          <h1 className="visually-hidden">TSS</h1>
          <img alt="TSS" src={logoImg} className="header-logo" />
        </IonButtons>
        <IonButtons slot="end" className="h-100p">
          {currentUser && (
            <IonButton onClick={logOut} className="h-100p">
              <img alt="Log Out" src={logoutImg} />
            </IonButton>
          )}
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  )
}

Header.propTypes = propTypes
Header.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    currentUser: authSelectors.currentUser(state),
  }
}

const mapDispatchToProps = {
  logOutUser: apiActions.logOutUser,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Header)
