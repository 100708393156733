import React from 'react'
import PropTypes from 'prop-types'
import { IonLabel, IonRadioGroup, IonRadio, IonItem } from '@ionic/react'
import { withFormikAdapter } from 'utils'
import * as Types from 'types'
import InputError from './InputError'
import { startCase } from 'lodash'

const propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(Types.selectOption),
  isTouched: PropTypes.bool.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  error: PropTypes.string,
  hidden: PropTypes.bool,
  labelComponent: Types.labelComponent,
  required: PropTypes.bool,
  requiredIndicator: PropTypes.string,
}

const defaultProps = {
  labelComponent: IonLabel,
  label: '',
  error: '',
}

function RadioGroup({
  name,
  options,
  label,
  labelComponent: LabelComponent,
  isTouched,
  error,
  hidden,
  required,
  requiredIndicator,
  ...rest
}) {
  const labelText = label ?? startCase(name)
  const invalid = Boolean(error)

  return (
    <IonItem className="input-container" hidden={hidden} lines="none">
      <LabelComponent position="stacked" className="stacked-label">
        {labelText}
        {required && requiredIndicator && (
          <span className="required-indicator" aria-hidden="true">
            {requiredIndicator}
          </span>
        )}
      </LabelComponent>
      <IonRadioGroup name={name} className="input-container" {...rest}>
        {options.map(({ label, value }) => (
          <IonItem key={value} className="radio-container" lines="none">
            <IonRadio
              name={name}
              justify="start"
              labelPlacement="end"
              value={value}
            >
              {label}
            </IonRadio>
          </IonItem>
        ))}
      </IonRadioGroup>
      <InputError
        name={name}
        error={error}
        touched={isTouched}
        invalid={invalid}
      />{' '}
    </IonItem>
  )
}

RadioGroup.propTypes = propTypes
RadioGroup.defaultProps = defaultProps

export default withFormikAdapter()(RadioGroup)
