import React from 'react'
import PropTypes from 'prop-types'
import { IonSearchbar } from '@ionic/react'
import { withFormikAdapter } from 'utils'
import { omit } from 'lodash'
import InputError from './InputError'

const propTypes = {
  name: PropTypes.string.isRequired,
  isTouched: PropTypes.bool.isRequired,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
}

const defaultProps = {
  error: '',
}

function Search({ name, isTouched, error, placeholder, type, ...rest }) {
  const invalid = Boolean(error)
  return (
    <div className="w-100p">
      <IonSearchbar
        className="searchbar"
        name={name}
        placeholder={placeholder}
        type={type}
        {...omit(rest, 'aria-describedby')}
      />
      <InputError
        name={name}
        error={error}
        touched={isTouched}
        invalid={invalid}
      />
    </div>
  )
}

Search.propTypes = propTypes
Search.defaultProps = defaultProps

export default withFormikAdapter()(Search)
