import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { IonCard, IonText } from '@ionic/react'
import { user, ERROR_CODE } from 'types'
import { selectors as authSelectors } from 'auth-reducer'
import WorkOrderSearchForm from '../forms/WorkOrderSearchForm'
import * as apiActions from 'api-actions'
import { useHistory } from 'react-router-dom'
import { getApiErrorCode } from 'utils'

const propTypes = {
  searchWorkOrder: PropTypes.func.isRequired,
  currentUser: user,
}
const defaultProps = {}

function WorkOrderSearch({ searchWorkOrder, currentUser }) {
  const history = useHistory()

  return (
    <section>
      <IonText className="showpage-title" color="light">
        <h2>{`Welcome, ${currentUser.name}`}</h2>
      </IonText>
      <IonCard className="ion-padding padding-bottom-2 card-styling">
        <h3 className="m-t-0">Enter Your Work Order Number</h3>
        <p className="body-large">
          Enter your exact 8-digit work order number. You don’t need to include
          “WO” in your search.
        </p>
        <WorkOrderSearchForm
          initialValues={{ workOrderNumber: '' }}
          onSubmit={async (data) => {
            try {
              const result = await searchWorkOrder(data.workOrderNumber)
              history.push(`/work_orders/show/${result.id}`)
            } catch (error) {
              const errors = error.errors
              const errorCode = getApiErrorCode(errors)
              // only redirect here if we're failing bc of an Api response, not a form error
              if (errorCode === ERROR_CODE.NOT_FOUND) {
                history.push('/work_orders/no_results')
              }
              if (errorCode == ERROR_CODE.NOT_FOR_TSS) {
                history.push('/work_orders/customer_app_work_order')
              }
            }
          }}
        />
      </IonCard>
    </section>
  )
}

WorkOrderSearch.propTypes = propTypes
WorkOrderSearch.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    currentUser: authSelectors.currentUser(state),
  }
}

const mapDispatchToProps = {
  searchWorkOrder: apiActions.searchWorkOrder,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  WorkOrderSearch
)
