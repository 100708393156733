import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { IonItem, IonThumbnail } from '@ionic/react'

import * as Types from 'types'

const propTypes = {
  image: Types.image.isRequired,
  onClick: PropTypes.func.isRequired,
}
const defaultProps = {}

function TripImage({ image, onClick }) {
  return (
    <IonItem className="trip-image" button onClick={onClick}>
      <IonThumbnail slot="start">
        <img alt={image.filename} src={image.url} />
      </IonThumbnail>
      <p>{image.filename}</p>
    </IonItem>
  )
}

TripImage.propTypes = exact(propTypes)
TripImage.defaultProps = defaultProps

export default React.memo(TripImage)
