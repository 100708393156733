import React from 'react'
import { Form, Formik } from 'formik'
import { SubmitButton, Input, FormWideErrorReporter } from 'formik-components'
import PropTypes from 'prop-types'
import * as Yup from 'yup'

const propTypes = {
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

const defaultProps = {}

const VALIDATION_SCHEMA = Yup.object({
  email: Yup.string()
    .email('Email must be valid')
    .required("Email can't be blank"),
})

function ForgotPasswordForm({ initialValues, onSubmit }) {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={VALIDATION_SCHEMA}
      onSubmit={onSubmit}
      validateOnBlur={false}
    >
      {({ isSubmitting }) => {
        return (
          <Form>
            <FormWideErrorReporter />
            <Input
              name="email"
              label="Email"
              type="email"
              required={true}
              requiredIndicator="*"
              placeholder="Enter email address"
              dataTest="email"
            />
            <SubmitButton isSubmitting={isSubmitting}>Send a Link</SubmitButton>
          </Form>
        )
      }}
    </Formik>
  )
}

ForgotPasswordForm.propTypes = propTypes
ForgotPasswordForm.defaultProps = defaultProps

export default React.memo(ForgotPasswordForm)
