import React, { useEffect } from 'react'
import exact from 'prop-types-exact'
import { useSubmitMessages } from 'utils'
import { useFormikContext } from 'formik'

const propTypes = {}
const defaultProps = {}

// Note that this looks like it could just be a hook, but we seem to only get useFormikContext inside of a Formik component,
// and if we put useFormikContext inside the props function or inside of a hook, we get an error that says "Cannot use hooks inside of a callback."
function FormWideErrorReporter() {
  const displaySubmitFailure = useSubmitMessages().displaySubmitFailure

  const { isSubmitting, isValid, isValidating, submitCount, errors } =
    useFormikContext()

  useEffect(() => {
    if (isValid || isValidating || !isSubmitting) return
    displaySubmitFailure(errors)
  }, [
    isSubmitting,
    isValid,
    isValidating,
    submitCount,
    errors,
    displaySubmitFailure,
  ])

  return null
}

FormWideErrorReporter.propTypes = exact(propTypes)
FormWideErrorReporter.defaultProps = defaultProps

export default React.memo(FormWideErrorReporter)
