import React from 'react'
import PropTypes from 'prop-types'
import { IonContent, IonPage } from '@ionic/react'
import { Header } from 'components'
import logoImg from 'images/tss-grey-logo.svg'

const propTypes = {
  children: PropTypes.node.isRequired,
}
const defaultProps = {}

function Layout({ children }) {
  return (
    <IonPage>
      <Header />
      <IonContent>
        <div className="content-container">
          <div className="content-container-internal">
            {children}
            <div className="tss-logo-img-container">
              {<img alt="TSS" src={logoImg} className="background-img" />}
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  )
}

Layout.propTypes = propTypes
Layout.defaultProps = defaultProps

export default Layout
