import React from 'react'
import PropTypes from 'prop-types'
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonModal,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import { closeOutline } from 'ionicons/icons'

const propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  dismissModal: PropTypes.func.isRequired,
  header: PropTypes.string,
}
const defaultProps = {}

function Modal({ children, isOpen, dismissModal, header, ...rest }) {
  return (
    <IonModal
      className="modal-styling"
      isOpen={isOpen}
      onDidDismiss={dismissModal}
      {...rest}
    >
      <IonHeader className="modal-header">
        <IonToolbar>
          {header && <IonTitle>{header}</IonTitle>}
          <IonButtons slot="end">
            <IonButton
              onClick={dismissModal}
              fill="clear"
              size="medium"
              color="dark"
            >
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">{children}</IonContent>
    </IonModal>
  )
}

Modal.propTypes = propTypes
Modal.defaultProps = defaultProps

export default React.memo(Modal)
