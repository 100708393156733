import { get } from 'lodash'
import { ERROR_MESSAGES } from 'types'
import isValidationError from './isValidationError'
import formatApiErrorMessages from './formatApiErrorMessages'

/**
 * This function determines the error message to display for submit failures,

 * @name getFormWideErrorMessage
 * @param {Object} errors - Errors that caused submit to fail
 * @param {Object} submitError - Errors that caused submit to fail. If the only errors are related internally to Formik validations, this will be null
 */

function getFormWideErrorMessage(errors, submitError) {
  // For some reason, ESLint is not recognizing the GeolocationPositionError as a class, but this class is what is
  // returned from the geolocation API when there is an error
  // This check for window.GeolocationPositionError is a workaround based on this stack overflow: https://stackoverflow.com/questions/70759484/geolocationpositionerror-is-not-defined
  if (submitError instanceof window.GeolocationPositionError) {
    return `${ERROR_MESSAGES.GEO_DENIAL_ERROR}: ${submitError.message}`
  } else if (isValidationError(errors, submitError))
    return get(
      errors,
      '_error',
      'Please review the information provided and correct all form errors.'
    )
  else {
    return formatApiErrorMessages(errors)
  }
}

export default getFormWideErrorMessage
