import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import * as Types from 'types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectors as authSelectors } from 'auth-reducer'
import { IonSpinner } from '@ionic/react'
import * as apiActions from 'api-actions'
import { useToast } from 'utils'

const propTypes = {
  children: PropTypes.node.isRequired,
  fetchCurrentUser: PropTypes.func.isRequired,
  currentUser: Types.user,
}

const defaultProps = {}

function PrivateRoutes({ children, fetchCurrentUser, currentUser }) {
  const history = useHistory()
  const [showToast] = useToast()

  const redirectIfUnauthorized = useCallback(async () => {
    try {
      await fetchCurrentUser()
    } catch (error) {
      // only redirect if unauthorized error
      if (error.status === 401) {
        showToast({
          message: 'Please log in to continue.',
          color: 'warning',
          duration: 3000,
        })
        history.replace('/auth/login')
      }
    }
  }, [fetchCurrentUser, history, showToast])

  useEffect(() => {
    if (!currentUser) {
      redirectIfUnauthorized()
    }
  }, [currentUser, redirectIfUnauthorized])

  return currentUser ? children : <IonSpinner />
}

PrivateRoutes.propTypes = propTypes
PrivateRoutes.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    currentUser: authSelectors.currentUser(state),
  }
}

const mapDispatchToProps = {
  fetchCurrentUser: apiActions.fetchCurrentUser,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  PrivateRoutes
)
