import { configureApi, http } from 'lp-requests'
import { middleware as configureMiddleware } from 'lp-redux-api'
import { storageGet } from 'local-storage'
import * as authActions from 'auth-actions'

// Configure lp-redux-api middleware

// This function will be passed the request options before every request.
// You can use it to set additional options or override existing ones.
async function before({ authenticated }) {
  if (!authenticated) return
  return {
    bearerToken: await storageGet('token'),
  }
}

// clear "currentUser" from store on 401
function onUnauthorized() {
  return function (dispatch) {
    dispatch(authActions.clearCurrentUser())
  }
}

// Any transformations of successful responses can go here.
function onSuccess(res) {
  return res?.data
}

// Any transformations of failed responses can go here.
function onFailure(res) {
  return res
}

export const config = {
  root: process.env.API_URL,
  before,
  onUnauthorized,
  onSuccess,
  onFailure,
  mode: 'cors',
}

export const middleware = configureMiddleware(http, config)
export const api = configureApi(config)
