import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { IonList } from '@ionic/react'

import TripImage from './TripImage'
import * as Types from 'types'

const propTypes = {
  selectImage: PropTypes.func.isRequired,
  trip: Types.trip.isRequired,
}
const defaultProps = {}

function TripQuestionnaireResponse({ trip, selectImage }) {
  const { allQuestionResponses } = trip

  return allQuestionResponses.map((question, index) => {
    const { questionText } = question

    return (
      <div key={index}>
        <h4>{questionText}</h4>
        <QuestionResponse
          key={index}
          question={question}
          selectImage={selectImage}
        />
      </div>
    )
  })
}

TripQuestionnaireResponse.propTypes = exact(propTypes)
TripQuestionnaireResponse.defaultProps = defaultProps

export default React.memo(TripQuestionnaireResponse)

// eslint-disable-next-line no-unused-vars
function QuestionResponse({ question, selectImage }) {
  const { questionResponse, type } = question

  switch (type) {
    // questionResponse is a string for choice and input-type questions
    case Types.QUESTION_TYPE.CHOICE:
    case Types.QUESTION_TYPE.INPUT:
      return <p>{questionResponse}</p>

    // questionReponse is an array of photos for photo-type responses
    case Types.QUESTION_TYPE.PHOTO: {
      return (
        <IonList lines="none">
          {questionResponse.length > 0 ? (
            questionResponse.map((image) => (
              <TripImage
                key={image.id}
                image={image}
                onClick={() => selectImage(image)}
              />
            ))
          ) : (
            <p>No images</p>
          )}
        </IonList>
      )
    }
  }
}
