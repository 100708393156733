import React from 'react'
import PropTypes from 'prop-types'
import { IonTextarea, IonItem } from '@ionic/react'
import { withFormikAdapter } from 'utils'
import classNames from 'classnames'
import { omit, startCase } from 'lodash'

const propTypes = {
  name: PropTypes.string.isRequired,
  isTouched: PropTypes.bool.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  error: PropTypes.string,
  className: PropTypes.string,
  hidden: PropTypes.bool,
}

const defaultProps = {
  label: '',
  error: '',
  className: '',
}

function Textarea({
  name,
  label,
  isTouched,
  error,
  className,
  hidden,
  ...rest
}) {
  const labelText = label ?? startCase(name)
  return (
    <IonItem className="input-container" lines="none" hidden={hidden}>
      <IonTextarea
        className={classNames(className, {
          'ion-invalid': error,
          'ion-touched': isTouched,
        })}
        name={name}
        label={labelText}
        labelPlacement="stacked"
        errorText={error}
        {...omit(rest, 'aria-describedby')} // uses built-in ionic error components
      ></IonTextarea>
    </IonItem>
  )
}

Textarea.propTypes = propTypes
Textarea.defaultProps = defaultProps

export default withFormikAdapter()(Textarea)
