export default function transformDeviseErrors(errors) {
  // This error from the API is different from other errors. The error here is what Devise gives us
  // out of the box, and we are not intercepting it in the API. The format of the object error is this:
  // { errors: { email: ['not found'] } }
  // There will only ever be one error.
  // We need to transform this into the format we expect:
  // { errors: [{ title: 'Email not found' }] }
  if (Array.isArray(errors)) {
    return errors
  } else {
    const errorMessage =
      Object.keys(errors).length === 0
        ? 'An unidentified error occurred.'
        : Object.keys(errors)
            .map((key) => {
              const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1)
              return `${capitalizedKey} ${errors[key][0]}`
            })
            .join('. ') + '.'
    return [{ title: errorMessage }]
  }
}
