import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { IonSelect, IonSelectOption, IonItem, IonLabel } from '@ionic/react'
import { withFormikAdapter } from 'utils'
import classNames from 'classnames'
import InputError from './InputError'
import { startCase } from 'lodash'

const propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(Types.selectOption),
  isTouched: PropTypes.bool.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  error: PropTypes.string,
  className: PropTypes.string,
  labelComponent: Types.labelComponent,
  hidden: PropTypes.bool,
  required: PropTypes.bool,
  requiredIndicator: PropTypes.string,
  type: PropTypes.string,
}

const defaultProps = {
  label: '',
  error: '',
  className: '',
  labelComponent: IonLabel,
}

function Select({
  name,
  options,
  label,
  isTouched,
  error,
  labelComponent: LabelComponent,
  className,
  hidden,
  required,
  requiredIndicator,
  type,
  ...rest
}) {
  const labelText = label ?? startCase(name)
  const invalid = Boolean(error)
  return (
    <IonItem class="input-container" lines="none" hidden={hidden}>
      <LabelComponent position="stacked" className="stacked-label">
        {labelText}
        {required && requiredIndicator && (
          <span className="required-indicator" aria-hidden="true">
            {requiredIndicator}
          </span>
        )}
      </LabelComponent>
      <IonSelect
        className={classNames(
          className,
          {
            'ion-invalid': error,
            'ion-touched': isTouched,
          },
          'text-input',
          'input-margin'
        )}
        name={name}
        multiple={type === 'multi-select'}
        interface={type === 'multi-select' ? 'alert' : 'action-sheet'}
        interfaceOptions={{ cssClass: 'select-interface' }}
        {...rest}
      >
        {options &&
          options.map((option) => {
            return (
              <IonSelectOption value={option.value} key={option.value}>
                {option.label}
              </IonSelectOption>
            )
          })}
      </IonSelect>
      <InputError
        name={name}
        error={error}
        touched={isTouched}
        invalid={invalid}
      />
    </IonItem>
  )
}

Select.propTypes = propTypes
Select.defaultProps = defaultProps

export default withFormikAdapter()(Select)
