import React from 'react'
import { Form, Formik } from 'formik'
import { Search, SubmitButton, FormWideErrorReporter } from 'formik-components'
import PropTypes from 'prop-types'
import * as Yup from 'yup'

const propTypes = {
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

const defaultProps = {}

const VALIDATION_SCHEMA = Yup.object({
  workOrderNumber: Yup.string()
    .required("Work order number can't be blank")
    .matches(/^[0-9]+$/, 'Must be only digits')
    .length(8, 'Work order number must be exactly 8 characters'),
})

function WorkOrderSearchForm({ initialValues, onSubmit }) {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={VALIDATION_SCHEMA}
      onSubmit={onSubmit}
      validateOnBlur={false}
    >
      {({ isSubmitting }) => {
        return (
          <Form>
            <FormWideErrorReporter />
            <div className="work-order-search-container">
              <Search
                name="workOrderNumber"
                type="search"
                placeholder="Search Work Order"
              />
              <SubmitButton
                isSubmitting={isSubmitting}
                buttonClassName="search-submit-button"
                expand="block"
              >
                Search
              </SubmitButton>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

WorkOrderSearchForm.propTypes = propTypes
WorkOrderSearchForm.defaultProps = defaultProps

export default React.memo(WorkOrderSearchForm)
