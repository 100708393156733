import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { IonDatetime, IonLabel } from '@ionic/react'
import { withFormikAdapter } from 'utils'
import InputError from './InputError'

const propTypes = {
  name: PropTypes.string.isRequired,
  isTouched: PropTypes.bool.isRequired,
  onIonChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  multiple: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  error: PropTypes.string,
  labelComponent: Types.labelComponent,
  labelProps: PropTypes.object,
}

const defaultProps = {
  value: null,
  multiple: false,
  label: '',
  error: '',
  labelComponent: IonLabel,
  labelProps: {},
}

function Date({
  name,
  isTouched,
  onIonChange,
  value,
  multiple,
  label,
  error,
  labelComponent: LabelComponent,
  labelProps,
  ...rest
}) {
  const labelId = label && !rest['aria-label'] ? `label-${name}` : undefined

  return (
    <>
      {label && (
        <LabelComponent id={labelId} {...labelProps}>
          {label}
        </LabelComponent>
      )}
      <IonDatetime
        name={name}
        aria-labelledby={labelId}
        onIonChange={(e) => {
          // Since Formik expects values to be defined for validation, convert undefined to its initial value when date(s) are unselected
          if (e.target.value === undefined) {
            e.target.value = multiple ? [] : ''
          }
          return onIonChange(e)
        }}
        value={value === '' ? null : value} // Empty string is not a valid option for ion-datetime value
        multiple={multiple}
        {...rest}
      />
      <InputError {...{ isTouched, error, name }} />
    </>
  )
}

Date.propTypes = propTypes
Date.defaultProps = defaultProps

export default withFormikAdapter()(Date)
