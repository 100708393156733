import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import * as apiActions from 'api-actions'
import { useSubmitMessages, transformDeviseErrors } from 'utils'
import { useHistory } from 'react-router-dom'
import ForgotPasswordForm from '../forms/ForgotPasswordForm'

const propTypes = {
  requestPasswordReset: PropTypes.func.isRequired,
}
const defaultProps = {}

function ForgotPassword({ requestPasswordReset }) {
  const history = useHistory()
  const { displaySubmitSuccess, displaySubmitFailure } = useSubmitMessages()

  return (
    <>
      <div className="login-content">
        <h1>Reset Password</h1>
        <p>
          Enter your email and we’ll send you a link to reset your password.
        </p>
        <ForgotPasswordForm
          initialValues={{
            email: '',
          }}
          onSubmit={async (params) => {
            try {
              await requestPasswordReset(params)
              await displaySubmitSuccess(
                'Reset password link has succesfully been sent.'
              )
              history.replace('/auth/login')
            } catch (error) {
              const errors = transformDeviseErrors(error.errors)
              displaySubmitFailure(errors, null, error)
            }
          }}
        />
      </div>
    </>
  )
}

ForgotPassword.propTypes = propTypes
ForgotPassword.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {
  requestPasswordReset: apiActions.requestPasswordReset,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ForgotPassword
)
