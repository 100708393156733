import { GEO_BROWSER_OPTIONS, GEO_DEVICE_OPTIONS } from '../main/types'

function getBrowserFromUserAgent(userAgent) {
  // Source: https://www.geeksforgeeks.org/how-to-detect-the-user-browser-safari-chrome-ie-firefox-and-opera-using-javascript/

  // Currently, this function only detects Chrome and Safari

  // Detect Chrome
  let chromeAgent = userAgent.match(/Chrome/i)

  // Detect Safari
  let safariAgent = userAgent.match(/Safari/i)

  // Discard Safari since it also matches Chrome
  if (chromeAgent && safariAgent) safariAgent = false

  // Chrome may have both 'Chrome' and 'Safari' in the userAgent string
  // Safari always has just Safari
  if (chromeAgent) {
    return GEO_BROWSER_OPTIONS.CHROME
  } else if (safariAgent) {
    return GEO_BROWSER_OPTIONS.SAFARI
  } else {
    return 'Unknown'
  }
}

function getDeviceFromUserAgent(userAgent) {
  // Source: https://stackoverflow.com/questions/3514784/what-is-the-best-way-to-detect-a-mobile-device

  // Currently, this function only detects iPhone, iPad, Android, Windows, and Macintosh

  if (userAgent.match(/iPhone/i) || userAgent.match(/iPod/i)) {
    return GEO_DEVICE_OPTIONS.IPHONE
  } else if (userAgent.match(/iPad/i)) {
    return GEO_DEVICE_OPTIONS.IPAD
  } else if (userAgent.match(/Android/i)) {
    return GEO_DEVICE_OPTIONS.ANDROID
  } else if (userAgent.match(/Windows/i)) {
    return GEO_DEVICE_OPTIONS.WINDOWS
  } else if (userAgent.match(/Macintosh/i)) {
    return GEO_DEVICE_OPTIONS.MAC
  } else {
    return 'Unknown'
  }
}

export default function getBrowserAndDevice() {
  // This function is very prone to change, so take it with a grain of salt

  const userAgent = navigator.userAgent
  const browser = getBrowserFromUserAgent(userAgent)
  const device = getDeviceFromUserAgent(userAgent)
  return {
    browser: browser,
    device: device,
  }
}
