import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import * as Views from './views'
import Layout from './Layout'
import RouterOutlet from '../../components/RouterOutlet'

const propTypes = {}
const defaultProps = {}

function Routes() {
  const { path } = useRouteMatch()

  const routes = [
    {
      url: `${path}/login`,
      component: Views.Login,
    },
    {
      url: `${path}/forgotpassword`,
      component: Views.ForgotPassword,
    },
    {
      url: `${path}/resetpassword/:token`,
      component: Views.ResetPassword,
    },
    {
      url: `${path}/createaccount/:token`,
      component: Views.CreateAccount,
    },
  ]

  return <RouterOutlet routes={routes} Layout={Layout} />
}

Routes.propTypes = propTypes
Routes.defaultProps = defaultProps

export default Routes
