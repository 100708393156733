import { startCase } from 'lodash'

function buildSelectOptions(collection = [], skipLabelFormatting = false) {
  return collection.map((item) => {
    return {
      label: skipLabelFormatting ? item : startCase(item),
      value: item,
    }
  })
}

export default buildSelectOptions
