import React from 'react'

const propTypes = {}
const defaultProps = {}

function SafariAndroidDocs() {
  return (
    <div>
      <h1 className="mr-auto margin-top-20">
        Please switch to using Google Chrome with your Android phone for this
        app
      </h1>
    </div>
  )
}

SafariAndroidDocs.propTypes = propTypes
SafariAndroidDocs.defaultProps = defaultProps

export default React.memo(SafariAndroidDocs)
