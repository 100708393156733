import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { IonRouterOutlet } from '@ionic/react'
import * as Types from 'types'
import { Route } from 'react-router-dom'

const propTypes = {
  routes: PropTypes.arrayOf(Types.route),
  Layout: PropTypes.elementType,
}

const defaultProps = {}

// Wrapper for IonRouter outlet

function RouterOutlet({ routes, Layout }) {
  return (
    <IonRouterOutlet>
      {routes.map((data) => {
        const { provider: Provider, component: Component } = data

        const componentWithLayout = Layout ? (
          <Layout {...data.layoutParams}>
            <Component />
          </Layout>
        ) : (
          <Component />
        )

        return (
          <Route path={data.url} key={data.url} exact={data.exact}>
            {Provider ? (
              <Provider>{componentWithLayout}</Provider>
            ) : (
              componentWithLayout
            )}
          </Route>
        )
      })}
    </IonRouterOutlet>
  )
}

RouterOutlet.propTypes = exact(propTypes)
RouterOutlet.defaultProps = defaultProps

export default RouterOutlet
