import { handleActions } from 'redux-actions'
import { set, unset } from 'lodash/fp'
import { storageSet, storageRemove } from 'local-storage'
import { handleSuccess, handleResponse } from 'lp-redux-api'
import { selectorForSlice } from 'lp-redux-utils'
import * as actions from './actions'
import * as apiActions from 'api-actions'

const reducerKey = 'auth'
const slice = 'root.auth'

const initialState = {}

const reducer = handleActions(
  {
    [apiActions.authenticateUser]: handleSuccess(storeUserAndToken),
    [apiActions.fetchCurrentUser]: handleResponse(
      storeUserAndToken,
      clearUserAndToken
    ),
    // we clear the token even if logout fails on the API side to avoid "trapping" the user as logged in
    [apiActions.logOutUser]: handleResponse(
      clearUserAndToken,
      clearUserAndToken
    ),
    [actions.clearCurrentUser]: clearUserAndToken,
  },
  initialState
)

// Helpers
function storeUserAndToken(state, { payload: { data } }) {
  // Store auth token in local storage
  if (data.token) storageSet('token', data.token)
  return set('currentUser', data, state)
}

function clearUserAndToken(state) {
  storageRemove('token')
  return unset('currentUser', state)
}

const select = selectorForSlice(slice)

const selectors = {
  currentUser: select('currentUser'),
}

export { reducer, selectors, reducerKey }
