import React from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { Routes as AuthRoutes } from './auth'
import { Routes as WorkOrdersRoutes } from './workOrders'
import { Routes as GeolocationDocsRoutes } from './geolocationDocs'
import PrivateRoutes from '../components/PrivateRoutes'
import RouterOutlet from '../components/RouterOutlet'

const propTypes = {}
const defaultProps = {}

function Routes() {
  const privateRoutes = [
    {
      url: '/work_orders',
      component: WorkOrdersRoutes,
    },
  ]

  return (
    <Switch>
      <Redirect exact from="/" to="/work_orders/search" />
      <Route path="/auth" component={AuthRoutes} />
      <Route path="/geolocation_docs" component={GeolocationDocsRoutes} />
      <PrivateRoutes>
        <RouterOutlet routes={privateRoutes} />
      </PrivateRoutes>
    </Switch>
  )
}

Routes.propTypes = propTypes
Routes.defaultProps = defaultProps

export default Routes
