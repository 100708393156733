import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { IonButton } from '@ionic/react'
import noResultsImage from 'images/no-results.svg'
import { Link } from 'react-router-dom'

const propTypes = {}
const defaultProps = {}

function WorkOrderSearchNoResults() {
  return (
    <div className="flex-col margin-top">
      <div className="mx-auto">
        <img alt="No Results Found" src={noResultsImage} />
      </div>
      <p className="mx-auto">There were no results for your search</p>
      <Link to="/work_orders/search" className="mx-auto">
        <IonButton>Try Another Number</IonButton>
      </Link>
    </div>
  )
}

WorkOrderSearchNoResults.propTypes = propTypes
WorkOrderSearchNoResults.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  WorkOrderSearchNoResults
)
