import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import { isEmpty, isNil } from 'lodash'
import { QuestionnaireForm } from '../forms'
import * as apiActions from 'api-actions'
import { selectors } from '../reducer'
import { PHOTO_TYPE, QUESTION_TYPE, Questionnaire, trip } from 'types'
import {
  findImagesOfType,
  useSubmitMessages,
  filterQuestionnaireResponses,
  formatQuestionnaireResponses,
} from 'utils'

const propTypes = {
  createQuestionnaireResponses: PropTypes.func.isRequired,
  currentQuestionnaire: Questionnaire.isRequired,
  currentTrip: trip,
}
const defaultProps = {
  currentQuestionnaire: [],
}

function WorkOrderCheckedIn({
  createQuestionnaireResponses,
  currentQuestionnaire,
  currentTrip,
}) {
  const history = useHistory()
  const { workOrderId } = useParams()
  const { displaySubmitFailure } = useSubmitMessages()

  if (isNil(currentTrip))
    return <Redirect to={`/work_orders/show/${workOrderId}`} />

  return (
    <section>
      <header>
        <h2>Thank you for checking in!</h2>
      </header>

      <article>
        <h3>
          Please provide the following information before getting started:
        </h3>
        <QuestionnaireForm
          initialValues={loadQuestionnaireForm(
            currentTrip,
            currentQuestionnaire
          )}
          questionnaire={currentQuestionnaire}
          onSubmit={async (questionnaireResponses) => {
            try {
              const filteredFormattedResponses = filterAndFormatResponses(
                questionnaireResponses,
                currentQuestionnaire,
                currentTrip
              )
              await createQuestionnaireResponses(
                currentTrip.id,
                filteredFormattedResponses
              )
              history.replace(`/work_orders/show/${workOrderId}`)
            } catch (error) {
              const errors = error.errors
              displaySubmitFailure(errors, null, error)
            }
          }}
        />
      </article>
    </section>
  )
}

WorkOrderCheckedIn.propTypes = propTypes
WorkOrderCheckedIn.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    currentQuestionnaire: selectors.currentQuestionnaire(state),
    currentTrip: selectors.currentTrip(state),
  }
}

const mapDispatchToProps = {
  createQuestionnaireResponses: apiActions.createQuestionnaireResponses,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  WorkOrderCheckedIn
)

function filterAndFormatResponses(questionResponses, questionnaire, trip) {
  // Filter the questionnaire reponses to only those that will be submitted.
  // For instance, we don't submit photo responses to the API, as those have already been uploaded.
  const filteredRepsonses = filterQuestionnaireResponses(
    questionResponses,
    questionnaire
  )

  return formatQuestionnaireResponses(filteredRepsonses, questionnaire, trip)
}

function loadQuestionnaireForm(currentTrip, questionnaire) {
  if (isEmpty(questionnaire)) return {}
  const { currentTripImages } = currentTrip

  return questionnaire.reduce((initialValues, question) => {
    const { fieldName, questionId, type } = question
    let value = ''
    if (type == QUESTION_TYPE.PHOTO) {
      const { photoType } = question
      value =
        photoType === PHOTO_TYPE.CUSTOM
          ? findCustomImagesForQuestion(currentTripImages, questionId)
          : findImagesOfType(currentTripImages, photoType)
    }
    initialValues[fieldName] = value
    return initialValues
  }, {})
}

function findCustomImagesForQuestion(images, customQuestionId) {
  return images
    .filter(({ questionId }) => questionId === customQuestionId)
    .map(({ filename, id, url }) => ({
      id,
      name: filename,
      type: 'image/*',
      url,
    }))
}
