export default function runGeolocationCheck(
  handleGeoSuccess,
  requireGeolocationCheck,
  geoOptions = { enableHighAccuracy: true, timeout: 10000 }
) {
  // Because getCurrentPosition is an async function (not a promise), we need to wrap it in a promise
  // In order to handle its responses asynchronously
  return new Promise((resolve, reject) => {
    if (!requireGeolocationCheck) {
      // If using geolocation isn't required, we can resolve the promise
      // directly with the result of handleSuccessfulGeoLocation.
      handleGeoSuccess()
        .then(() => {
          resolve()
        })
        .catch((error) => reject(error))
    } else if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords
          // For now we just take one reading of the lat and lng and pass to the backend
          // but the backend is structured so that it can receive an array of lat / lng objects,
          // so if we want to take multiple readings in the future, we can pass them all at once
          // to the backend
          const coordinates = [{ lat: latitude, lng: longitude }]
          handleGeoSuccess(coordinates)
            .then(() => {
              resolve()
            })
            .catch((error) => {
              reject(error)
            })
        },
        (error) => {
          // error.code can be:
          //   0: unknown error
          //   1: permission denied
          //   2: position unavailable (error response from location provider)
          //   3: timed out
          reject(error)
        },
        geoOptions
      )
    } else {
      reject({
        message:
          'Geolocation is required for using this app, but it is not supported by this browser. Please use a modern browser like Google Chrome, Firefox, Safari, or Microsoft Edge.',
      })
    }
  })
}
