import { combineReducers } from 'redux'
import { reducer as authReducer, reducerKey as authReducerKey } from './auth'
import {
  reducer as workOrdersReducer,
  reducerKey as workOrdersReducerKey,
} from './workOrders'

const reducerKey = 'root'

const reducer = combineReducers({
  [authReducerKey]: authReducer,
  [workOrdersReducerKey]: workOrdersReducer,
})

export { reducer, reducerKey }
