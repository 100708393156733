import React from 'react'
import PropTypes from 'prop-types'
import { IonNote } from '@ionic/react'

import { generateInputErrorId } from 'utils'

const propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  invalid: PropTypes.bool,
  name: PropTypes.string,
  touched: PropTypes.bool,
}
const defaultProps = {
  error: null,
  invalid: false,
  name: '',
  touched: false,
}

function InputError({ invalid, error, name, touched }) {
  if (invalid && touched) {
    return (
      <IonNote className="input-error" color="danger">
        <span id={generateInputErrorId(name)}>{formatError(error)}</span>
      </IonNote>
    )
  }

  return null
}

function formatError(error) {
  return Array.isArray(error) ? error.join(', ') : error
}

InputError.propTypes = propTypes
InputError.defaultProps = defaultProps

export default React.memo(InputError)
