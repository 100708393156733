import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import { WorkOrderGeoDocs } from '../workOrders/views'
import Layout from './Layout'
import RouterOutlet from '../../components/RouterOutlet'

const propTypes = {}
const defaultProps = {}

function Routes() {
  const { path } = useRouteMatch()

  const routes = [
    {
      url: `${path}/`,
      component: WorkOrderGeoDocs,
      layoutParams: {
        backButtonText: 'Back',
      },
    },
  ]

  return <RouterOutlet routes={routes} Layout={Layout} />
}

Routes.propTypes = propTypes
Routes.defaultProps = defaultProps

export default Routes
