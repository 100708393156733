import React from 'react'
import { AMAZON_GEO_DOCS_URL } from 'types'

const propTypes = {}
const defaultProps = {}

function ChromeComputerDocs() {
  const computerChromeOne = `${AMAZON_GEO_DOCS_URL}/computer-chrome-1.png`
  const computerChromeTwo = `${AMAZON_GEO_DOCS_URL}/computer-chrome-2.png`
  const computerChromeThree = `${AMAZON_GEO_DOCS_URL}/computer-chrome-3.png`
  return (
    <div>
      <h1 className="mr-auto margin-top-20">
        For Google Chrome Windows or Mac Computers:
      </h1>
      <h3 className="mr-auto margin-top-20">
        1. First, click on the circle and dash icon to the left of the URL bar.
      </h3>
      <img
        alt="Chrome computer-1"
        src={computerChromeOne}
        className="geo-doc-img geo-doc-img-wider"
      />
      <h3 className="mr-auto margin-top-20">
        2. Next to the word, "Location", click on the button that is slided in
        the "off" position into the "on" position.
      </h3>
      <img
        alt="Chrome computer-2"
        src={computerChromeTwo}
        className="geo-doc-img geo-doc-img-wider"
      />
      <h3 className="mr-auto margin-top-20">
        3. Now click outside of the box you were in and you should see a notice
        at the top of the screen asking you to Reload the page. Click the
        "Reload" button
      </h3>
      <img
        alt="Chrome computer-3"
        src={computerChromeThree}
        className="geo-doc-img geo-doc-img-wider"
      />
      <h3 className="mr-auto margin-top-20">
        4. Now you should be able to check-in or check-out. Please click on the
        "Back to Work Order" button at the top of your screen to continue.
      </h3>
    </div>
  )
}

ChromeComputerDocs.propTypes = propTypes
ChromeComputerDocs.defaultProps = defaultProps

export default React.memo(ChromeComputerDocs)
