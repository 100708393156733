import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { useHistory, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { IonButton, IonText, useIonViewDidLeave } from '@ionic/react'
import { isEmpty, isNil } from 'lodash'

import { LoadingModal, Modal } from 'components'
import { StatusTile, WorkOrderPreview } from '../components'
import { WorkOrderCheckInForm, WorkOrderCheckoutForm } from '../forms'
import WorkOrderSearchNoResults from './WorkOrderSearchNoResults'
import WorkOrderCustomerAppInstructions from './WorkOrderCustomerAppInstructions'
import { selectors } from '../reducer'
import * as actions from '../actions'
import * as apiActions from 'api-actions'

import {
  ERROR_CODE,
  ORDER_STATUS,
  ORDER_SUB_STATUS,
  PHOTO_TYPE,
  trip,
  workOrder,
} from 'types'
import {
  findImagesOfType,
  getApiErrorCode,
  isValidationError,
  useSubmitMessages,
  runGeolocationCheck,
  checkIfRootCauseNeglectOrDefect,
} from 'utils'

const propTypes = {
  checkingOut: PropTypes.bool,
  clearWorkOrder: PropTypes.func.isRequired,
  createTrip: PropTypes.func.isRequired,
  currentTrip: trip,
  fetchWorkOrder: PropTypes.func.isRequired,
  startCheckOut: PropTypes.func.isRequired,
  stopCheckOut: PropTypes.func.isRequired,
  updateTrip: PropTypes.func.isRequired,
  workOrder: workOrder,
}
const defaultProps = {}

function WorkOrderShow({
  checkingOut,
  clearWorkOrder,
  createTrip,
  currentTrip,
  fetchWorkOrder,
  startCheckOut,
  stopCheckOut,
  updateTrip,
  workOrder,
}) {
  const { workOrderId } = useParams()

  const [isImageModalOpen, setIsImageModalOpen] = useState(false)
  const [selectedImage, setSelectedImage] = useState({})
  const selectImage = (image) => {
    setIsImageModalOpen(true)
    setSelectedImage(image)
  }

  useEffect(() => {
    const fetchData = () => fetchWorkOrder(workOrderId)

    // This catch is useful for Dev only - webpacker always wants to display an error if we don't do this
    process.env.NODE_ENV === 'development'
      ? fetchData().catch(() => {})
      : fetchData()
    return () => {
      clearWorkOrder()
    }
  }, [clearWorkOrder, fetchWorkOrder, workOrderId])

  useIonViewDidLeave(() => {
    stopCheckOut()
  })

  if (workOrder?.notFound) return <WorkOrderSearchNoResults />
  if (workOrder?.isForCustomerApp) return <WorkOrderCustomerAppInstructions />

  if (!workOrder) return <LoadingModal />
  return (
    <section>
      <Modal
        isOpen={isImageModalOpen}
        dismissModal={() => setIsImageModalOpen(false)}
        header={selectedImage?.filename}
      >
        <img
          alt={selectedImage?.filename}
          src={`${selectedImage?.url}`}
          className="work-order-image-full"
        />
      </Modal>

      <header>
        <h2>{workOrder.location?.name}</h2>
        <div className="flex">
          <IonText
            color="primary"
            className="body-small mr-auto flex align-center"
          >
            <span>{workOrder.name}</span>
          </IonText>
          <StatusTile status={workOrder.orderStatus} />
        </div>
      </header>
      <hr />
      <WorkOrderPreview {...{ selectImage, workOrder }} />
      <NextWorkOrderStep
        {...{
          checkingOut,
          createTrip,
          currentTrip,
          fetchWorkOrder,
          startCheckOut,
          stopCheckOut,
          updateTrip,
          workOrder,
        }}
      />
    </section>
  )
}

WorkOrderShow.propTypes = propTypes
WorkOrderShow.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    workOrder: selectors.workOrder(state),
    currentTrip: selectors.currentTrip(state),
    checkingOut: selectors.checkingOut(state),
  }
}

const mapDispatchToProps = {
  fetchWorkOrder: apiActions.fetchWorkOrder,
  createTrip: apiActions.createTrip,
  updateTrip: apiActions.updateTrip,
  clearWorkOrder: actions.clearWorkOrder,
  startCheckOut: actions.startCheckOut,
  stopCheckOut: actions.stopCheckOut,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  WorkOrderShow
)

function NextWorkOrderStep({
  checkingOut,
  createTrip,
  currentTrip,
  fetchWorkOrder,
  startCheckOut,
  stopCheckOut,
  updateTrip,
  workOrder,
}) {
  const history = useHistory()
  const { displaySubmitFailure, displaySubmitSuccess } = useSubmitMessages()
  const { orderStatus, orderSubStatus } = workOrder

  // When a work order has no currentTrip consider its questionnaire as completed.
  const questionnaireCompleted = currentTrip?.questionnaireCompleted ?? true

  const requireGeolocationCheck = workOrder.shouldRunGeolocationCheck

  const handleCheckoutFailure = (errors, dispatch, submitError) => {
    displaySubmitFailure(errors, dispatch, submitError)
    if (!isValidationError(errors, submitError)) {
      const errorCode = getApiErrorCode(errors)
      if (errorCode === ERROR_CODE.TRIP_TIMED_OUT) {
        stopCheckOut()
        fetchWorkOrder(workOrder.id)
      }
    }
  }

  const handleCheckInSubmit = () => {
    const createTripWithCoordinates = (coordinates = []) => {
      return createTrip(
        {
          workOrderId: workOrder.id,
        },
        coordinates
      )
    }
    return runGeolocationCheck(
      createTripWithCoordinates,
      requireGeolocationCheck
    )
  }

  const handleCheckoutSubmit = (formValues) => {
    // Photo fields already were uploaded to the server, so we don't need to include them on this submission:
    const alwaysExcludedFields = [
      'abusePhotos',
      'defectPhotos',
      'unitConditionPhotos',
      'workPerformedPhotos',
    ]

    // If the rootCause is neglect or defect, we don't need to exclude the abuseNeglectClaims field
    const fieldsToExclude = [
      ...alwaysExcludedFields,
      ...(checkIfRootCauseNeglectOrDefect(formValues.rootCause)
        ? []
        : ['abuseNeglectClaims']),
    ]
    const formValuesForSubmission = Object.keys(formValues).reduce(
      (newObj, key) => {
        if (!fieldsToExclude.includes(key)) {
          newObj[key] = formValues[key]
        }
        return newObj
      },
      {}
    )

    const updateTripWithCoordinates = (coordinates = []) => {
      return updateTrip(currentTrip.id, formValuesForSubmission, coordinates)
    }
    return runGeolocationCheck(
      updateTripWithCoordinates,
      requireGeolocationCheck
    )
  }

  // If a work order has timed out, force the user to check in again. Otherwise,
  // require the user to check in if the work order is in any state other than
  // "Tech on Site"
  if (
    orderSubStatus === ORDER_SUB_STATUS.IVR_TIMEOUT ||
    orderStatus !== ORDER_STATUS.TECH_ON_SITE
  ) {
    return (
      <WorkOrderCheckInForm
        requireGeolocationCheck={requireGeolocationCheck}
        initialValues={{}}
        onSubmit={async () => {
          try {
            await handleCheckInSubmit()
            history.replace(`/work_orders/checked_in/${workOrder.id}`)
          } catch (error) {
            const errors = error.errors
            displaySubmitFailure(errors, null, error)
          }
        }}
      />
    )
  } else {
    if (checkingOut) {
      const currentPhotos = loadCurrentPhotos(currentTrip)

      return (
        <WorkOrderCheckoutForm
          initialValues={{
            rootCause: '',
            operable: '',
            status: '',
            notes: '',
            abuseNeglectClaims: '',
            ...currentPhotos,
          }}
          onSubmit={async (formValues) => {
            try {
              await handleCheckoutSubmit(formValues)
              await displaySubmitSuccess('You have been checked out!')
              history.replace('/')
            } catch (error) {
              const errors = error.errors
              handleCheckoutFailure(errors, null, error)
            }
          }}
        />
      )
    } else if (questionnaireCompleted) {
      return (
        <div className="w-100p">
          <IonButton
            expand="full"
            onClick={() => startCheckOut()}
            shape="round"
            size="large"
          >
            Continue to Checkout
          </IonButton>
        </div>
      )
    } else {
      return (
        <div className="w-100p">
          <IonButton
            expand="full"
            onClick={() =>
              history.replace(`/work_orders/checked_in/${workOrder.id}`)
            }
            shape="round"
            size="large"
          >
            Complete Questionnaire
          </IonButton>
        </div>
      )
    }
  }
}

function loadCurrentPhotos(currentTrip) {
  const defaultResult = {
    workPerformedPhotos: [],
    unitConditionPhotos: [],
    abusePhotos: [],
    defectPhotos: [],
  }
  if (isNil(currentTrip)) return defaultResult

  const { currentTripImages } = currentTrip

  if (isEmpty(currentTripImages)) return defaultResult

  return {
    workPerformedPhotos: findImagesOfType(
      currentTripImages,
      PHOTO_TYPE.WORK_PERFORMED
    ),
    unitConditionPhotos: findImagesOfType(
      currentTripImages,
      PHOTO_TYPE.UNIT_CONDITION
    ),
    abusePhotos: findImagesOfType(currentTripImages, PHOTO_TYPE.ABUSE),
    defectPhotos: findImagesOfType(currentTripImages, PHOTO_TYPE.DEFECT),
  }
}
