import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import * as apiActions from 'api-actions'
import { useSubmitMessages, transformDeviseErrors } from 'utils'
import { useHistory, useParams } from 'react-router-dom'
import ResetPasswordForm from '../forms/ResetPasswordForm'

const propTypes = {
  resetPassword: PropTypes.func.isRequired,
}
const defaultProps = {}

function ResetPassword({ resetPassword }) {
  const history = useHistory()
  const { displaySubmitSuccess, displaySubmitFailure } = useSubmitMessages()
  const { token } = useParams()
  return (
    <div className="login-content">
      <h1>Create New Password</h1>
      <ResetPasswordForm
        isNewPassword={false}
        initialValues={{ password: '', passwordConfirmation: '' }}
        onSubmit={async (params) => {
          try {
            await resetPassword({ ...params, reset_password_token: token })
            await displaySubmitSuccess('Password updated!')

            history.replace('/auth/login')
          } catch (error) {
            const errors = transformDeviseErrors(error.errors)
            displaySubmitFailure(errors, null, error)
          }
        }}
      />
    </div>
  )
}

ResetPassword.propTypes = propTypes
ResetPassword.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {
  resetPassword: apiActions.resetPassword,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ResetPassword
)
