import React from 'react'
import { AMAZON_GEO_DOCS_URL } from 'types'

const propTypes = {}
const defaultProps = {}

function ChromeAndroidDocs() {
  const androidChromeOne = `${AMAZON_GEO_DOCS_URL}/android-chrome-1.png`
  const androidChromeTwo = `${AMAZON_GEO_DOCS_URL}/android-chrome-2.png`
  const androidChromeThree = `${AMAZON_GEO_DOCS_URL}/android-chrome-3.png`
  return (
    <div>
      <h1 className="mr-auto margin-top-20">
        For Google Chrome Android Phones:
      </h1>
      <h3 className="mr-auto margin-top-20">
        1. First, click on the circle and dash icon to the left of the URL bar.
      </h3>
      <img
        alt="Chrome Android-1"
        src={androidChromeOne}
        className="geo-doc-img"
      />
      <h3 className="mr-auto margin-top-20">
        2. Next, click on the "Permissions" option.
      </h3>
      <img
        alt="Chrome Android-2"
        src={androidChromeTwo}
        className="geo-doc-img"
      />
      <h3 className="mr-auto margin-top-20">
        3. Finally, next to the word, "Location", click on the button that is
        slided in the "off" position into the "on" position.
      </h3>
      <img
        alt="Chrome Android-3"
        src={androidChromeThree}
        className="geo-doc-img"
      />
      <h3 className="mr-auto margin-top-20">
        4. Now you should be able to check-in or check-out. Please click on the
        "Back to Work Order" button at the top of your screen to continue.
      </h3>
    </div>
  )
}

ChromeAndroidDocs.propTypes = propTypes
ChromeAndroidDocs.defaultProps = defaultProps

export default React.memo(ChromeAndroidDocs)
