import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { IonText } from '@ionic/react'

import StatusTile from './StatusTile'
import TripQuestionnaireResponse from './TripQuestionnaireResponse'
import { formatUTCAsLocalTime } from 'utils'
import * as Types from 'types'

const propTypes = {
  workOrder: Types.workOrder.isRequired,
  trip: Types.trip.isRequired,
  selectImage: PropTypes.func.isRequired,
}

const defaultProps = {}

function TripPreview({ workOrder, trip, selectImage }) {
  const { createdAt, notes, abuseNeglectClaims, operable, rootCause, status } =
    trip
  const isCheckedOutTrip = ![
    Types.TRIP_STATUS.CLOSED_NO_CHECKOUT,
    Types.TRIP_STATUS.EXPIRED,
  ].includes(status)
  const abuseDefectHeaderText = Types.ROOT_CAUSE_NEGLECT_OPTIONS.includes(
    rootCause
  )
    ? 'Abuse'
    : 'Defect'
  return (
    <div className="trip-preview-container">
      <div className="flex">
        <IonText
          color="tss-grey-dark"
          className="body-small mr-auto flex align-center"
        >
          <span>{workOrder.name}</span>
        </IonText>
        <StatusTile status={trip.status} />
      </div>

      <hr />

      <h4 className="m-t-8">Checked In</h4>
      <p>{formatUTCAsLocalTime(createdAt)}</p>

      {isCheckedOutTrip && (
        <>
          <h4>Notes</h4>
          <p>{notes}</p>
          {(Types.ROOT_CAUSE_NEGLECT_OPTIONS.includes(rootCause) ||
            rootCause == Types.ROOT_CAUSE_DEFECT) && (
            <>
              <h4>{abuseDefectHeaderText} Notes</h4>
              <p>{abuseNeglectClaims}</p>
            </>
          )}

          <h4>Root Cause</h4>
          <p>{rootCause}</p>

          <h4>Operational?</h4>
          <p>{operable}</p>

          <TripQuestionnaireResponse trip={trip} selectImage={selectImage} />
        </>
      )}
    </div>
  )
}
TripPreview.propTypes = exact(propTypes)
TripPreview.defaultProps = defaultProps

export default React.memo(TripPreview)
