import { isEmpty } from 'lodash'

import fieldNameFromTitle from './fieldNameFromTitle'

/* eslint-disable no-unused-vars */
export default function decorateQuestionnaireWithFieldNames(questions) {
  if (isEmpty(questions)) {
    throw new Error('Must provide questions for decoration')
  }

  return questions.map((question) => ({
    ...question,
    fieldName: fieldNameFromTitle(question?.title),
  }))
}
