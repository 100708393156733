import { handleActions } from 'redux-actions'
import { selectorForSlice, unsetState, setState } from 'lp-redux-utils'
import { handleResponse, handleSuccess } from 'lp-redux-api'
import { set } from 'lodash/fp'
import { compose } from 'redux'

import * as actions from './actions'
import * as apiActions from 'api-actions'

import { decorateQuestionnaireWithFieldNames } from 'utils'

const reducerKey = 'workOrder'
const slice = 'root.workOrder'

const initialState = {}
const reducer = handleActions(
  {
    [apiActions.fetchWorkOrder]: handleResponse(
      storeWorkOrderAndCurrentTrip,
      handleFailedFetchWorkOrder
    ),
    [apiActions.searchWorkOrder]: handleSuccess(storeWorkOrderAndCurrentTrip),
    [apiActions.createTrip]: handleSuccess(storeWorkOrderAndCurrentTrip),
    [apiActions.updateTrip]: handleSuccess(storeWorkOrderAndCurrentTrip),
    [actions.clearWorkOrder]: unsetState('workOrder'),
    [actions.startCheckOut]: setState('checkingOut', true),
    [actions.stopCheckOut]: setState('checkingOut', false),
  },
  initialState
)

function storeWorkOrderAndCurrentTrip(state, { payload: { data: workOrder } }) {
  return compose(
    set('currentTrip', workOrder.trips && workOrder.trips.at(-1)),
    set(
      'currentQuestionnaire',
      decorateQuestionnaireWithFieldNames(workOrder.questionnaire)
    ),
    set('workOrder', workOrder)
  )(state)
}

function handleFailedFetchWorkOrder(state, { payload: { data } }) {
  // Normally we would use ERROR_CODE here to check for the errorCode comparison, rather than using these strings,
  // but for some totally unknown reason,
  // it seems that handleResponse does not understand how to use imported constants.
  // I (Will the developer) went over this with the maintainer of the app, Conor, about this and he was stumped as well.
  // For now we are just going to leave it as a string check, as trying to figure out how to fix this would be very onerous.
  if (data?.errors[0]?.errorCode === 'not_found') {
    return setState('workOrder', { notFound: true })(state)
  } else if (data?.errors[0]?.errorCode === 'not_for_tss_app') {
    return setState('workOrder', { isForCustomerApp: true })(state)
  } else {
    return state
  }
}

const select = selectorForSlice(slice)

const selectors = {
  checkingOut: select('checkingOut'),
  currentTrip: select('currentTrip'),
  currentQuestionnaire: select('currentQuestionnaire'),
  workOrder: select('workOrder'),
}

export { reducer, selectors, reducerKey }
