import React from 'react'
import { Form, Formik } from 'formik'
import { SubmitButton, Input, FormWideErrorReporter } from 'formik-components'
import PropTypes from 'prop-types'
import * as Yup from 'yup'

// This form is used for both "Reset Password" and "Create New Password"

const propTypes = {
  isNewPassword: PropTypes.bool.isRequired,
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

const defaultProps = {}

const VALIDATION_SCHEMA = Yup.object({
  password: Yup.string()
    .required("Password can't be blank")
    .min(8, 'Password must be at least 8 characters'),
  passwordConfirmation: Yup.string()
    .required("Password confirmation can't be blank")
    .oneOf([Yup.ref('password')], "Passwords don't match"),
})

function ResetPasswordForm({ isNewPassword, initialValues, onSubmit }) {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={VALIDATION_SCHEMA}
      onSubmit={onSubmit}
      validateOnBlur={false}
    >
      {({ isSubmitting }) => {
        return (
          <Form>
            <FormWideErrorReporter />
            <Input
              name="password"
              label={isNewPassword ? 'Create Password' : 'New Password'}
              type="password"
              required={true}
              requiredIndicator="*"
              placeholder="Must be at least 8 characters"
            />
            <Input
              name="passwordConfirmation"
              label="Re-Enter Password"
              type="password"
              required={true}
              requiredIndicator="*"
              placeholder="Must be at least 8 characters"
            />
            <SubmitButton isSubmitting={isSubmitting}>
              Submit New Password
            </SubmitButton>
          </Form>
        )
      }}
    </Formik>
  )
}

ResetPasswordForm.propTypes = propTypes
ResetPasswordForm.defaultProps = defaultProps

export default React.memo(ResetPasswordForm)
