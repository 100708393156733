import React from 'react'
import PropTypes from 'prop-types'
import { IonCheckbox } from '@ionic/react'
import { union as addToArray, xor as removeFromArray } from 'lodash'
import { withFormikAdapter, serializeOptions } from 'utils'
import * as Types from 'types'
import InputError from './InputError'

const propTypes = {
  name: PropTypes.string.isRequired,
  options: Types.fieldOptions.isRequired,
  isTouched: PropTypes.bool.isRequired,
  onIonChange: PropTypes.func.isRequired,
  onIonBlur: PropTypes.func.isRequired,
  onIonInput: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  error: PropTypes.string,
  labelProps: PropTypes.object,
  checkboxInputProps: PropTypes.object,
  fieldsetClassName: PropTypes.string,
}

const defaultProps = {
  value: [],
  label: '',
  error: '',
  labelProps: {},
  checkboxInputProps: {},
  fieldsetClassName: '',
}

function CheckboxGroup({
  name,
  options,
  isTouched,
  onIonChange,
  onIonBlur,
  onIonInput,
  value,
  label,
  error,
  labelProps,
  checkboxInputProps,
  fieldsetClassName,
  ...rest
}) {
  const optionObjects = serializeOptions(options)

  const composeChangeEventHandler = (option) => {
    return function (event) {
      const isChecked = event.detail.checked
      const newValueArray = isChecked
        ? addToArray([option.value], value)
        : removeFromArray([option.value], value)
      return onIonChange(newValueArray)
    }
  }

  return (
    <>
      <fieldset className={fieldsetClassName} {...rest}>
        {label && <legend {...labelProps}>{label}</legend>}
        {optionObjects.map((option) => (
          <IonCheckbox
            key={option.value}
            name={`${name}.${option.value}`}
            value={value.includes(option.value)}
            onIonChange={composeChangeEventHandler(option)}
            onIonBlur={onIonBlur}
            onIonInput={onIonInput}
            {...checkboxInputProps}
          >
            {option.key}
          </IonCheckbox>
        ))}
      </fieldset>
      <InputError {...{ isTouched, error, name }} />
    </>
  )
}

CheckboxGroup.propTypes = propTypes
CheckboxGroup.defaultProps = defaultProps

export default withFormikAdapter()(CheckboxGroup)
