import React from 'react'
import { AMAZON_GEO_DOCS_URL } from 'types'

const propTypes = {}
const defaultProps = {}

function SafariIPhoneDocs() {
  const firstSafariIPhoneButton = `${AMAZON_GEO_DOCS_URL}/iPhone-safari-with-highlighting.png`
  const safariIPhoneWebsiteSettings = `${AMAZON_GEO_DOCS_URL}/iPhone-safari-menu-with-highlighting.png`
  const safariIPhoneLocationMenu = `${AMAZON_GEO_DOCS_URL}/iPhone-safari-location-menu-with-highlighting.png`
  const safariIPhoneLocationAllow = `${AMAZON_GEO_DOCS_URL}/iPhone-safari-location-ask-with-highlighting.png`
  const safariIPhoneRefresh = `${AMAZON_GEO_DOCS_URL}/iPhone-safari-refresh.png`
  return (
    <div>
      <h1 className="mr-auto margin-top-20">For Safari iPhones:</h1>
      <h3 className="mr-auto margin-top-20">
        1. You should click on the "AA" button next to the URL at the bottom of
        your page shown here:
      </h3>
      <img
        alt="Safari iPhone First Button"
        src={firstSafariIPhoneButton}
        className="geo-doc-img"
      />
      <h3 className="mr-auto margin-top-20">
        2. Then you should click "Website Settings" in the menu like so:
      </h3>
      <img
        alt="Safari iPhone Website Settings"
        src={safariIPhoneWebsiteSettings}
        className="geo-doc-img"
      />
      <h3 className="mr-auto margin-top-20">
        3. Then you should click the words to the right of "Location" in the
        menu (which may say "Ask" or "Deny") like so:
      </h3>
      <img
        alt="Safari iPhone Location Menu"
        src={safariIPhoneLocationMenu}
        className="geo-doc-img"
      />
      <h3 className="mr-auto margin-top-20">
        4. Then you should click the "Allow" button like so:
      </h3>
      <img
        alt="Safari iPhone Location Allow"
        src={safariIPhoneLocationAllow}
        className="geo-doc-img"
      />
      <h3 className="mr-auto margin-top-20">
        5. Now your location settings have been set to "Allow". You can click
        the "Done" button in the top right.
      </h3>
      <h3 className="mr-auto margin-top-20">
        6. Next you will need to refresh the page for this affect to take place.
        Click the refresh button next to the URL bar:
      </h3>
      <img
        alt="Safari iPhone Refresh"
        src={safariIPhoneRefresh}
        className="geo-doc-img"
      />
      <h3 className="mr-auto margin-top-20">
        6. You should now be able to Check In or Check Out without being asked
        to give your geolocation. Please click on the "Back to Work Order"
        button at the top of your screen to get back to the Work Order page
        where you can Check In or Check Out.
      </h3>
    </div>
  )
}

SafariIPhoneDocs.propTypes = propTypes
SafariIPhoneDocs.defaultProps = defaultProps

export default React.memo(SafariIPhoneDocs)
