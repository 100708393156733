import React from 'react'
import PropTypes from 'prop-types'
import { IonCheckbox } from '@ionic/react'
import { withFormikAdapter } from 'utils'
import InputError from './InputError'

const propTypes = {
  name: PropTypes.string.isRequired,
  isTouched: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.string,
}

const defaultProps = {
  error: '',
}

function Checkbox({ name, label, isTouched, error, ...rest }) {
  return (
    <>
      <IonCheckbox name={name} {...rest}>
        {label}
      </IonCheckbox>
      <InputError {...{ isTouched, error, name }} />
    </>
  )
}

Checkbox.propTypes = propTypes
Checkbox.defaultProps = defaultProps

export default withFormikAdapter()(Checkbox)
