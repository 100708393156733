import React from 'react'
import { Formik, Form } from 'formik'
import { Select } from 'formik-components'
import PropTypes from 'prop-types'
import * as Types from 'types'
import * as Yup from 'yup'
import { buildSelectOptions } from 'utils'

const propTypes = {
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
}
const defaultProps = {}

function WorkOrderGeoDocsForm({ initialValues, onSubmit }) {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object()}
      onSubmit={onSubmit}
    >
      {({ handleChange, submitForm }) => {
        return (
          <Form>
            <Select
              name="browser"
              label="Browser"
              placeholder="Select Browser"
              options={buildSelectOptions(
                Object.values(Types.GEO_BROWSER_OPTIONS)
              )}
              onChange={(event) => {
                handleChange(event)
                submitForm()
              }}
            />
            <Select
              name="device"
              label="Device"
              placeholder="Select Device"
              options={buildSelectOptions(
                Object.values(Types.GEO_DEVICE_OPTIONS)
              )}
              onChange={(event) => {
                handleChange(event)
                submitForm()
              }}
            />
          </Form>
        )
      }}
    </Formik>
  )
}

WorkOrderGeoDocsForm.propTypes = propTypes
WorkOrderGeoDocsForm.defaultProps = defaultProps

export default React.memo(WorkOrderGeoDocsForm)
