import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { IonInput, IonItem, IonLabel } from '@ionic/react'
import { withFormikAdapter } from 'utils'
import classNames from 'classnames'
import { omit, startCase } from 'lodash'
import InputError from './InputError'

const propTypes = {
  name: PropTypes.string.isRequired,
  isTouched: PropTypes.bool.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  error: PropTypes.string,
  className: PropTypes.string,
  labelComponent: Types.labelComponent,
  required: PropTypes.bool,
  requiredIndicator: PropTypes.string,
  hidden: PropTypes.bool,
  dataTest: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
}

const defaultProps = {
  label: '',
  error: '',
  className: '',
  labelComponent: IonLabel,
  required: false,
  hidden: false,
}

function Input({
  name,
  label,
  isTouched,
  error,
  labelComponent: LabelComponent,
  className,
  required,
  requiredIndicator,
  placeholder,
  hidden,
  type,
  dataTest,
  ...rest
}) {
  const labelText = label ?? startCase(name)
  const invalid = Boolean(error)

  return (
    <IonItem
      className="input-container text-input"
      lines="none"
      hidden={hidden}
    >
      <LabelComponent position="stacked" className="stacked-label">
        {labelText}
        {required && requiredIndicator && (
          <span className="required-indicator" aria-hidden="true">
            {requiredIndicator}
          </span>
        )}
      </LabelComponent>
      <IonInput
        className={classNames(className, 'input-textbox', {
          'ion-invalid': error,
          'ion-touched': isTouched,
        })}
        name={name}
        data-test={dataTest}
        placeholder={placeholder}
        type={type === 'input' ? 'text' : type}
        {...omit(rest, 'aria-describedby')}
      ></IonInput>
      <InputError
        name={name}
        error={error}
        touched={isTouched}
        invalid={invalid}
      />
    </IonItem>
  )
}

Input.propTypes = propTypes
Input.defaultProps = defaultProps

export default withFormikAdapter()(Input)
