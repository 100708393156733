import { first, isEmpty } from 'lodash'
import { ERROR_CODE } from 'types'

export default function getApiErrorCode(errors) {
  if (isEmpty(errors)) return ERROR_CODE.UNKNOWN_ERROR

  // API errors are formatted as in this example:
  // {
  //   "errors": [
  //      {
  //        "errorCode": "trip_timed_out",
  //        "title": "The trip timed out. You will be required to check in again to continue."
  //      }
  //   ]
  // }

  return first(errors)?.errorCode
}
