import { isArray, isString } from 'lodash'

export default function formatApiErrorMessages(errors) {
  // when coming from the API, errors are in an array,
  // map through them and return a concatenated string
  if (isArray(errors)) return combineErrorMessages(errors)

  return getErrorMessage(errors)
}

function getErrorMessage(errorObj = {}) {
  if (isString(errorObj)) return errorObj

  const { title, message, error } = errorObj

  if (!title && !error && !message)
    return 'A processing error occurred. Please contact customer support'

  return title || message || error
}

function combineErrorMessages(errors = []) {
  return errors.map(getErrorMessage).join('<br />')
}
