import React from 'react'
import exact from 'prop-types-exact'
import PropTypes from 'prop-types'
import { IonChip, IonLabel } from '@ionic/react'

const propTypes = {
  status: PropTypes.string,
}

const defaultProps = {}

// Status tile for WorkOrders and Trips
function StatusTile({ status }) {
  if (!status) return <></>

  const getColor = (status) => {
    switch (status) {
      case 'Tech On Site':
        return 'tss-orange'
      case 'Awaiting Parts':
        return 'tss-red'
      case 'Estimate':
        return 'tss-blue'
      case 'Scheduled':
        return 'tss-pink'
      case 'Work Completed':
        return 'tss-green'
      default:
        return 'tss-red'
    }
  }

  return (
    <IonChip color={getColor(status)}>
      <IonLabel>{status}</IonLabel>
    </IonChip>
  )
}

StatusTile.propTypes = exact(propTypes)
StatusTile.defaultProps = defaultProps

export default StatusTile
